const { overdueInvoiceTransactions, overdueInstalmentTransactions } = require('./common/overdue-payables')
const { pending } = require('./common/pending')
const writeLateFees = require('./common/late-fees-write')
const installmentsListSelector = require('./common/installments-list-selector')
const { transactionsSelect } = require('./pinned-transactions-list-query')
const {
  LATE_FEE_GRACE_PERIOD,
  LATE_FEE_DURATION_CAP,
  LATE_FEE_WEEKLY_PERCENTAGE,
  LATE_FEE_DAYS_IN_WEEK,
  LATE_FEE_AMOUNT_THRESHOLD
} = require('./../constants')

const installmentsSelect = installmentsListSelector(`WHERE ("amount" - coalesce("amount_paid", 0) > 0)`)

const createLateFeesQuery = ({writeMode = false, lateFeeThreshold, locationIdArgs, dueDateDifference = 0}) => {
  if (!lateFeeThreshold) {
    LATE_FEE_AMOUNT_THRESHOLD.ke = 0
    LATE_FEE_AMOUNT_THRESHOLD.ng = 0
  }
  const feeDurationPeriod = writeMode ? LATE_FEE_DAYS_IN_WEEK : LATE_FEE_DURATION_CAP
  const lateFeesWrite = writeMode ? `,${writeLateFees}` : ''
  const writeModeSelect = `
    select
      coalesce((select jsonb_agg(iv) from (select * from insert_invoices) iv), '[]'::jsonb) as "lateFeeInvoiceLines",
      coalesce((select jsonb_agg(il) from (select * from insert_installments) il), '[]'::jsonb) as "lateFeeInstalmentLines"
  `
  const pendingLateFeeFilter = dueDateDifference && typeof dueDateDifference === 'number' ? `and lf.fee_date <= now() + interval '${dueDateDifference} days'` : ''
  const lateFeeFilter = `where l.id is null ${writeMode ? 'and lf.fee_date <= now()' : pendingLateFeeFilter} and (coalesce(lfs.late_fee_accrual, false) != false or lfs.id is null)`
  const readModeSelect = `
    select
      di.location_id,
      null as paymentplan_id,
      null as installment_id,
      di.txn_id,
      di.company_code,
      di.description,
      di.txn_type,
      di.quickbooks_doc_number,
      di.amount,
      di.item_name,
      di.percentage,
      di.overdue_amount,
      di.fee_date as fee_date
    from diffed_invoice_lines di

    union all

    select
      dl.location_id,
      dl.paymentplan_id,
      dl.installment_id,
      null as txn_id,
      null as company_code,
      dl.description,
      'instalment' as txn_type,
      dl.quickbooks_doc_number,
      dl.amount,
      dl.item_name,
      dl.percentage,
      dl.overdue_amount,
      dl.fee_date as fee_date
    from diffed_installment_lines dl
  `
  const cte = [
    overdueInvoiceTransactions(locationIdArgs),
    overdueInstalmentTransactions(locationIdArgs),
    pending,
    `transactions_list AS (
      SELECT tl.* FROM (
        ${transactionsSelect}
        UNION
        SELECT * FROM (${installmentsSelect}) il
      ) tl
      LEFT OUTER JOIN pending p
        ON p.location_id = tl.location_id
       AND p.quickbooks_doc_number = tl.quickbooks_doc_number
      WHERE p.id IS NULL
    ),
    args as (
      select
        datetime_offset as late_fee_invoice_start_date
      from avocado.data_checkpoint where id = 'LATE_FEE_INVOICES_START_DATE'
    ),
    overdue_transactions AS (
      SELECT
        tl.*,
        a.late_fee_invoice_start_date,
        CASE
          WHEN l.fsid ilike '%country:ng%' then ${LATE_FEE_AMOUNT_THRESHOLD.ng}
          WHEN l.fsid ilike '%country:ke%' then ${LATE_FEE_AMOUNT_THRESHOLD.ke}
        END as amount_threshold
      FROM transactions_list tl
      left join avocado.data_location l on tl.location_id = l.uuid
      left join avocado.data_quickbookstransactions qt on qt.id = tl.id
      cross join args a
      WHERE now() >= tl.due_date ${writeMode ? ` + interval '${LATE_FEE_GRACE_PERIOD} days' ` : ''}
      and l.late_fee_accrual is true
      and l.supply_chain_level = 'sdp'
      and (
        (tl.txn_type = 'installment' and (tl.payment_plan ->> 'start_date')::timestamp >= a.late_fee_invoice_start_date)
        or
        (tl.txn_type = 'invoice' and tl.txn_date >= a.late_fee_invoice_start_date)
      )
      and not exists (
        select 1
        from avocado.data_invoice_quickbooks iq
        inner join avocado.data_invoice_line il on il.invoice_id = iq.invoice_id and il.event_type = 'fee'
        inner join avocado.late_fee lf on lf.event_id = il.event_id
        where iq.invoice_txn_id = qt.txn_id
          and iq.company_code = qt.company_code
          and lf.id is not null
        limit 1
      )
    ),
    generate_late_fee_lines as (
      select fee_line.*
      from overdue_transactions ot
      left join lateral (
        select
          null::uuid as event_id,
          ot.location_id,
          ot.txn_type,
          qt.txn_id,
          qt.company_code,
          pp.id as paymentplan_id,
          ppi.id as installment_id,
          ot.amount - coalesce(ot.amount_paid, 0) as overdue_amount,
          ${LATE_FEE_WEEKLY_PERCENTAGE}::decimal as fee_percentage,
          (${LATE_FEE_WEEKLY_PERCENTAGE}::decimal/100::decimal) * (ot.amount - coalesce(ot.amount_paid, 0)) as fee_amount,
          nth as fee_count,
          ((ot.due_date + interval '${LATE_FEE_GRACE_PERIOD} days') + ((nth - 1) || ' weeks')::interval )::date as fee_date,
          ot.quickbooks_doc_number
      FROM generate_series(1, (
        select
          (
          extract (day from date_trunc('day', now()) + interval '${feeDurationPeriod} days'
            -
          date_trunc('day', ot.due_date + interval '${LATE_FEE_GRACE_PERIOD} days'))::integer / ${LATE_FEE_DAYS_IN_WEEK}
          )
      )) nth
      left join avocado.data_quickbookstransactions qt on qt.id = ot.id
      left join avocado.data_paymentplan pp on pp.id = (ot.payment_plan ->> 'id')::uuid
      left join avocado.data_paymentplaninstallment ppi on ppi.id = ot.id
      ) fee_line on true
      where
        ((fee_line.txn_type = 'invoice' and fee_line.fee_date <= (date_trunc('day', ot.due_date) + interval '${LATE_FEE_DURATION_CAP} days'))
          or
        (fee_line.txn_type = 'installment' and fee_line.fee_date <= (date_trunc('day', (ot.payment_plan ->> 'start_date')::date) + interval '${LATE_FEE_DURATION_CAP} days')))
        and (ot.amount - coalesce(ot.amount_paid, 0)) > ot.amount_threshold
    ),
    late_fee_installments as (
      select
        event_id,
        location_id,
        paymentplan_id,
        installment_id,
        quickbooks_doc_number,
        concat(
          'Late Fee No. ', fee_count, ' for instalment - ',
          coalesce(quickbooks_doc_number, installment_id::text),
          '_', fee_date
        ) as description,
        fee_amount as amount,
        'Late Fee' as item_name,
        fee_percentage as percentage,
        overdue_amount,
        fee_date
      from generate_late_fee_lines where txn_type = 'installment'
    ),
    late_fee_invoices as (
      select
        event_id,
        location_id,
        txn_id,
        company_code,
        quickbooks_doc_number,
        txn_type,
        concat(
          'Late Fee No. ', fee_count, ' for invoice - ',
          coalesce(quickbooks_doc_number, concat(txn_id, ' - ', company_code)::text),
          '_', fee_date
        ) as description,
        fee_amount as amount,
        'Late Fee' as item_name,
        fee_percentage as percentage,
        overdue_amount,
        fee_date
      from generate_late_fee_lines where txn_type = 'invoice'
    ),
    diffed_installment_lines as (
      select
        lf.*
      from avocado.late_fee_installment l
      full outer join late_fee_installments lf on lf.paymentplan_id = l.paymentplan_id and lf.installment_id = l.installment_id and lf.fee_date = l.fee_date
      left join avocado.late_fee_installment_status lfs on lfs.installment_id = lf.installment_id
      ${lateFeeFilter}
    ),
    diffed_invoice_lines as (
      select
        lf.*
      from avocado.late_fee_invoice l
      full outer join late_fee_invoices lf on lf.txn_id = l.txn_id and lf.company_code = l.company_code and lf.fee_date = l.fee_date
      left join avocado.late_fee_invoice_status lfs on lfs.txn_id = lf.txn_id and lfs.company_code = lf.company_code
      ${lateFeeFilter}
    )
    ${lateFeesWrite}
    `
  ]
  return `
    WITH ${cte.join(', ')}
    ${writeMode ? writeModeSelect : readModeSelect}
  `
}

module.exports = { createLateFeesQuery }
