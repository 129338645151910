import { getIsUsingFlagsStatus } from './flag-usage-status'

// This configuration can be improved by making the values dynamic
// instead of hardcoding them to true.
const OVERRIDE_FEATURE_FLAGS = {
  routePlanning: true,
  longhaul: true,
  lastMileDeliveries: true,
  showDriverDeliveryView: true,
  showFacilityView: true,
  showShipmentSyncBanner: true
}

export const applyFeatureFlagOverrides = (config) => {
  // We store the flag usage in local storage because the withConfig HOC that gives us the config object
  // is used in components and other non-component contexts that may not have access to a ReduxProvider.
  // Attempting to access the Redux store in a non-component contexts would cause an error.
  // would cause an error.
  const isUsingFlags = getIsUsingFlagsStatus()

  // Return the original configuration if flag usage is not enabled.
  if (!isUsingFlags) {
    return config
  }

  // If flag usage is enabled, merge optional flags into the configuration.
  return {
    ...config,
    features: {
      ...config.features,
      ...OVERRIDE_FEATURE_FLAGS
    }
  }
}
