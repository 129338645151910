const { get: getLocation } = require('../../location')
const findDraft = require('./find-draft')
const { toDraft, fromDraft } = require('../../tools')
const updateStockCount = require('../../utils/update-stock-count')
const createReport = require('../tools/create')

module.exports = async (state, {
  locationId,
  service,
  period,
  stock = {},
  expiry = null,
  documents,
  isPristine = false,
  partialCount = false,
  submittedAt,
  dryRun = false
}) => {
  const location = await getLocation(state, locationId, period.effectiveStartDate)
  if (!location) {
    throw new Error('Location does not exist: ' + locationId)
  }
  if (!location.services.includes(service.id)) {
    throw new Error('Location has no active service: ' + service.id)
  }

  let draft
  const existingDraft = await findDraft(state, {locationId, service, period, entityOptions: { rawDocs: true }})
  if (existingDraft) {
    draft = updateStockCount(state, {stock, expiry}, existingDraft)
    delete draft.isPristine
  } else {
    draft = createReport({
      locationId,
      service,
      period,
      stock,
      expiry,
      username: state.user.name,
      isDraft: true,
      partialCount,
      submittedAt
    })
    if (isPristine) {
      draft.isPristine = true
    }

    // This is to debug SL opening balances #3973
    draft.documents = documents
  }
  draft._id = toDraft(draft._id)
  if (!dryRun) {
    await state.dal.report.write(state, draft)
  }
  draft._id = fromDraft(draft._id)

  return draft
}
