module.exports = getSubmitProperties

const locationIdToSubmitProperties = require('./location-id-to-submit-properties')

function getSubmitProperties (serviceId, locationId) {
  if (!serviceId) {
    // This `if` clause can be reviewed.
    // The places where this function is called without
    // providing a serviceId are:
    // api/lib/stock/data-access/nhlmis-rest-submit.js,
    // agave/src/modules/public-rest-api/location-controller.js,
    // backend/stacks/external_api/api_stock_count_submit/src/index.js,
    // backend/stacks/external_api/api_gets/src/index.js,
    // and web/src/subapps/reports/report-entry/ReportEntry.js
    // In all of them `shouldTrackBatches` is called with a location
    // but not a service.
    // What `locationIdToSubmitProperties` returns seems to be outdated
    // for some location levels, probably coming from VAN requirements.
    // Still, I am leaving it as is for now cause it seems to be
    // accidentally working (either that or there is a bug we don't know
    // about) and I don't want to break the NHLMIS api.
    return locationIdToSubmitProperties(locationId)
  }
  return { // same as sdp
    submitsOwnReport: false,
    submitsBatchedCounts: false,
    submitsMultiFieldCounts: true
  }
}
