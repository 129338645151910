import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { parse, stringify } from 'query-string'
import get from 'lodash/get'

import { Button } from '@fielded/shared-ui'
import { Arrow, CloseCircle } from '@fielded/shared-ui/src/icons'

import { selectMasterData } from '../../../root/reducers/shipments/master-data'
import { selectShipments } from '../../../root/reducers/shipments'
import { deleteProductFromShipment } from '../../../root/reducers/shipments/batches'
import Confirmation from '../confirmation/Confirmation'

const DeleteProductsConfirmContainer = ({
  history,
  match,
  location
}) => {
  const dispatch = useDispatch()
  const masterData = useSelector(selectMasterData)
  const shipments = useSelector(selectShipments)

  const search = get(location, 'search')
  const snapshotId = get(match, 'params.snapshotId')

  // we allow 1 - ?? deleted products in the params
  const { products = [] } = parse(search, {arrayFormat: 'bracket'})
  const productsFromStore = get(masterData, 'products', {})
  const productsById = get(productsFromStore, 'byId', {})

  const deleted = products
  const shipment = shipments[snapshotId]

  const onCancel = () => {
    const productsQuery = stringify({products: deleted}, {arrayFormat: 'bracket'})
    history.push(`/shipments/pick-list/${snapshotId}/shipment-options/remove-products?${productsQuery}`)
  }

  const onDeleteProducts = () => {
    // delete products one by one
    return deleted.reduce(
      (total, productId) => {
        return total.then(() => dispatch(deleteProductFromShipment(shipment, productId, productsFromStore)))
      },
      Promise.resolve()
    )
      .then(() => {
        if (shipment.status === 'received') {
          return history.push(`/shipments/adjustments/${snapshotId}`)
        }
        history.push(`/shipments/pick-list/${snapshotId}`)
      })
  }

  return (
    <Confirmation
      history={history}
      situation='danger'
      isBatchConfirmation
      isProceedNeeded={false}>
      <div className='content'>
        <header className='header--back header--light'>
          <h2 className='centered-page-title'>Confirm Shipment Modification</h2>
        </header>
        <section className='floating-card'>
          <h3 className='floating-card__heading'>Removing Products</h3>
          <p className='floating-card__subtitle'>
            The following products will be removed from the shipment:
          </p>
          <div className='floating-card__content--full-width'>
            <ul className='confirmation__list'>
              {deleted.map(id => (
                <li key={id} data-qa={`delete-product-confirm-${id}`} className='confirmation__list-item'>
                  <span className='confirmation__list-item-icon'>
                    <CloseCircle />
                  </span>
                  {productsById[id].name}
                </li>
              ))}
            </ul>
          </div>
          <div className='floating-card__footer'>
            <h3 className='floating-card__heading'>Is this correct?</h3>
            <div className='floating-card__spaced-buttons-wrapper'>
              <div className='floating-card__spaced-button'>
                <Button
                  onClick={onCancel}
                  fill='outline'
                  colorVariant='brand'
                  icon={<Arrow direction='left' />}
                >
                  No, back
                </Button>
              </div>
              <div className='floating-card__spaced-button'>
                <Button
                  onClick={onDeleteProducts}
                  fill='full'
                  colorVariant='brand'
                  icon={<Arrow direction='right' />}
                  iconSide='right'
                  className='qa-delete-product-confirm-save'
                >
                  Yes, save
                </Button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Confirmation>
  )
}

export default DeleteProductsConfirmContainer
