const createGraph = require('../../relationship-graph')
const { listAll } = require('../../location/api/read/list-all')
const findReports = require('./find')
const { listChildren: listLocationChildren } = require('../../location')
const ReportStatus = require('../../tools/utils/progress-statuses')
const { getPeriod, getNextPeriod, getPreviousPeriod } = require('./get-periods')
const { OFFLINE_ERROR } = require('../../utils/offline')

function getStatusOfLocationForService (location, service, reportsMap) {
  let reports = []
  let status = ReportStatus.NOT_STARTED
  const servicesMap = reportsMap.get(location._id)
  if (servicesMap && servicesMap.has(service.id)) {
    reports = servicesMap.get(service.id)
    for (const report of reports) {
      status = report.status
      if (status === ReportStatus.COMPLETE) {
        break
      }
    }
  }
  return Object.assign({}, service, {
    progress: {
      reports,
      status
    }
  })
}

function getStatusOfLocation (location, services, reportsMap) {
  const locationServiceIds = location.services
  const locationServices = services.filter(s => locationServiceIds.includes(s.id))
  const servicesProgress = locationServices.map(s => getStatusOfLocationForService(location, s, reportsMap))

  const total = servicesProgress.length
  const offline = servicesProgress.filter(s => s.progress.status === ReportStatus.OFFLINE).length
  const started = servicesProgress.filter(s => s.progress.status !== ReportStatus.NOT_STARTED).length
  const required = servicesProgress.filter(s => !s.isOptional).length
  const complete = servicesProgress.filter(s => s.progress.status === ReportStatus.COMPLETE).length
  const requiredComplete = servicesProgress.filter(s => !s.isOptional && s.progress.status === ReportStatus.COMPLETE).length

  return Object.assign({}, location, {
    canSubmit: true,
    progress: {
      total,
      complete,
      status: requiredComplete >= required ? ReportStatus.COMPLETE
        : offline === total ? ReportStatus.OFFLINE
          : started > 0 ? ReportStatus.IN_PROGRESS
            : ReportStatus.NOT_STARTED,
      services: servicesProgress
    }
  })
}

async function getReportsStatuses (state, {
  locations,
  locationsIsEntities,
  services,
  startDate,
  endDate
}) {
  const rows = await findReports(state, {
    locations,
    locationsIsEntities,
    services,
    startDate,
    endDate,
    queryOptions: { include_docs: false, useCache: true },
    entityOptions: { rawRows: true }
  })

  const locationsMap = new Map()
  for (const row of rows) {
    if (row.error && row.error === 'not_found') {
      continue
    }
    const status = row.error && row.error === OFFLINE_ERROR
      ? ReportStatus.OFFLINE
      : ReportStatus.COMPLETE

    const locationId = row.locationId
    const serviceId = row.service.id

    const servicesMap = locationsMap.get(locationId) || new Map()
    const reportsList = servicesMap.get(serviceId) || []

    reportsList.push({id: row.key, status})
    servicesMap.set(serviceId, reportsList)
    locationsMap.set(locationId, servicesMap)
  }
  return locationsMap
}

module.exports = async (state, {
  locationId,
  program,
  date,
  graph
}) => {
  if (!graph) {
    const allLocations = await listAll(state, { date })
    graph = createGraph(allLocations)
  }
  date = new Date(date)

  const activePeriod = await getPeriod(state, {program, date: new Date()})
  const currentPeriod = await getPeriod(state, {program, date})
  const nextPeriod = await getNextPeriod(state, {program, period: currentPeriod})
  const previousPeriod = await getPreviousPeriod(state, {program, period: currentPeriod})

  const periods = {
    active: activePeriod,
    current: currentPeriod,
    next: nextPeriod,
    previous: previousPeriod
  }
  const services = program.services
  const serviceIds = services.map(s => s.id)

  let parentLocation = await graph.getLocation(locationId)
  let childLocations = await listLocationChildren(state, locationId, {
    date: currentPeriod.effectiveStartDate,
    filters: {services: serviceIds},
    graph
  })

  // Ignore locations with no services
  if (parentLocation && parentLocation.services.length === 0) {
    parentLocation = null
  }
  childLocations = childLocations.filter(l => l.services.length > 0)

  const locations = (parentLocation ? [parentLocation] : []).concat(childLocations)

  // Get all report docs for the locations and services
  const reportsMap = await getReportsStatuses(state, {
    locations,
    locationsIsEntities: true,
    services: program.services,
    startDate: currentPeriod.effectiveStartDate,
    endDate: currentPeriod.effectiveEndDate
  })

  // Calculate the status of locations and services
  let parent
  if (parentLocation) {
    parent = getStatusOfLocation(parentLocation, program.services, reportsMap)
  }
  const children = childLocations.map(l => getStatusOfLocation(l, services, reportsMap))
  const all = (parent ? [parent] : []).concat(children)

  // Calculate the total status
  const total = all.length
  const started = all.filter(s => s.progress.status !== ReportStatus.NOT_STARTED).length
  const complete = all.reduce(
    (sum, s) => sum + (s.progress.status === ReportStatus.COMPLETE ? 1 : 0),
    0
  )

  return Object.assign({}, program, {
    locationId,
    periods,
    progress: {
      total: all.length,
      complete: complete,
      status: complete === total ? ReportStatus.COMPLETE
        : started > 0 ? ReportStatus.IN_PROGRESS
          : ReportStatus.NOT_STARTED
    },
    locations: {
      parent,
      children,
      all
    }
  })
}

module.exports.getStatusOfLocationForService = getStatusOfLocationForService
module.exports.getStatusOfLocation = getStatusOfLocation
module.exports.getReportsStatuses = getReportsStatuses
