module.exports = getLedgerBalance

const get = require('lodash/get')
const {listForLocations} = require('./product')
const { listReportFields } = require('./service')
const { getLatestReport } = require('./tools/get-ledger-balance')
const getAllocation = require('./allocation/api/get')
const gLB2 = require('./tools/get-ledger-balance2')
const findShipment = require('./shipment/shipment-find')
const readLedger = require('./ledger/read')
const { validateGetLedgerBalanceParams } = require('./validate/validators')
const offline = require('./shipment/tools/offline')
const findReports = require('./report/api/find')
const { get: getLocation } = require('./location/api/read/get')
const { sortByLatestReport } = require('./tools/date-utils')
const { getRelevantShipments } = require('./tools/get-relevant-shipments')

async function getExcludedProducts (state, params) {
  const exclusions = get(params, 'excludeSubscriptions', [])
  if (exclusions.length === 0) {
    return []
  }
  const allocationDoc = await getAllocation(state, params)

  if (!allocationDoc) {
    return []
  }

  return Object.keys(allocationDoc.products).filter(p => {
    // See DIRECT_ORDER_TYPES in lib/allocation/constants
    return exclusions.includes(get(allocationDoc, `products.${p}.forecast.type`))
  })
}

async function getLedgerBalance (state, params = {}) {
  const usageErrors = validateGetLedgerBalanceParams(params)
  if (usageErrors) {
    return Promise.reject(new Error(usageErrors[0].message))
  }

  const date = params.date || new Date().toJSON()
  const {
    locationId,
    service,
    since,
    withEntities,
    includeScheduledOutbound,
    includeOpenOrders,
    orders,
    ignoreService = false
  } = params

  let ledgerRead = Promise.resolve()
  // params.online is used server side
  // so we can avoid accumulating mistakes when we create new ledger balances
  if (!params.online) {
    ledgerRead = readLedger(state, locationId, date)
  }

  const {localOnly = true} = params

  const [ledgerResponse, reports] = await Promise.all([
    ledgerRead,
    findReports(state, {
      locationIds: [locationId],
      services: [service],
      startDate: since,
      endDate: date,
      queryOptions: {
        localOnly
      },
      ignoreService
    })
  ])

  if (ledgerResponse && ledgerResponse instanceof Error === false) {
    ledgerResponse.type = 'ledgerBalance' // so we can interpret reportId right
    reports.push(ledgerResponse)
  }

  const latest = getLatestReport(reports, date)
  const reportDate = latest ? latest.submittedAt : null

  let location = await getLocation(state, locationId, date)

  // Sometimes we get the ledger for locations without actual location docs like national or states
  if (location) {
    // Multiple other functions require an id prop, so we make sure we add it here.
    location = { ...location, id: locationId }
  } else {
    location = { id: locationId }
  }

  // For excluding products source
  // e.g SL we don't need service/configuration products
  const exclude = params.excludeProducts
  let [productsResponse, shipments, fields, excludeProducts] = await Promise.all([
    listForLocations(state, [locationId], { date, serviceId: service.id, exclude, idsOnly: false }),
    // Only read shipments that have an event happening between those dates:
    findShipment(state, { location: locationId, startdate: reportDate, enddate: date }, {checkSnapShotLocation: true})
      .catch(error => {
        if (error.status === offline.OFFLINE_ERROR) {
          return { offlineError: error }
        }

        throw error
      }),
    listReportFields(state, service.id),
    getExcludedProducts(state, { facilityId: location.id, date, serviceId: service.id, excludeSubscriptions: params.excludeSubscriptions })
  ])

  // If we get an offline error on the shipments and can't read
  // a valid ledger, then we need to throw an offline error
  if (shipments.offlineError) {
    if (reports.length === 0) {
      throw shipments.offlineError
    }

    // If we have a valid ledger, just use that:
    shipments = []
  }

  const products = productsResponse.products

  const baseReport = getBaseReport(reports, date)
  const lastReportDate = get(baseReport, 'submittedAt')

  const relevantShipments = getRelevantShipments(state, {shipments, location, date, lastReportDate, products, includeScheduledOutbound, service})

  const { ledger, addedShipments } = gLB2.getLedgerBalance({
    location,
    date,
    products,
    reports,
    relevantShipments,
    includeScheduledOutbound,
    service,
    fields,
    includeOpenOrders,
    orders
  })

  excludeProducts.forEach(productId => {
    if (ledger[productId]) {
      ledger[productId].skipCount = true
    }
  })

  if (!withEntities) {
    return ledger
  }

  return {
    fields,
    ledger,
    baseReport,
    addedShipments,
    shipments,
    products,
    // filter out server calculated ledger balance so it won't show up in bin card view
    reports: reports.filter(r => r !== ledgerResponse),
    preCalculatedLedger: ledgerResponse
  }
}

function getBaseReport (reports, date) {
  if (!reports) return null
  const sorted = reports
    .filter(r => !r.partialCount)
    .filter(r => {
      return r.submittedAt && r.submittedAt <= date
    })
    .sort(sortByLatestReport)

  return sorted.length ? sorted[sorted.length - 1] : null
}
