import React from 'react'

import { Button, SelectInput } from '@fielded/shared-ui'

class ReportingPeriodJumper extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      month: null,
      year: null,
      yearLimit: 2016
    }
  }

  setYear = ({ value }) => {
    this.setState({ year: value })
  }

  setMonth = ({ value }) => {
    this.setState({ month: value })
  }

  getYears = () => {
    let currentYear = new Date().getFullYear()
    const yearsOptions = []
    while (currentYear >= this.state.yearLimit) {
      yearsOptions.push({ label: currentYear, value: currentYear })
      currentYear = currentYear - 1
    }
    return yearsOptions
  }

  render () {
    const { month, year } = this.state
    const { handlePeriodJump, isBimonthly, isMonthly } = this.props
    const cycleOptions = isBimonthly ? BIMONTHLY_CYCLES : (isMonthly ? MONTHLY_CYCLES : QUARTERLY_CYCLES)

    return (
      <div className='timeframe__date-picker'>
        <div className='date-picker__time-wrapper'>
          <SelectInput
            clearable={false}
            options={this.getYears()}
            onChange={this.setYear}
            className={'date-picker__years'}
            placeholder={'Select a year...'}
            creatable={false}
          />
          <SelectInput
            clearable={false}
            options={cycleOptions}
            onChange={this.setMonth}
            className={'date-picker__cycles'}
            placeholder={'Select a cycle...'}
            creatable={false}
          />
        </div>
        <div className='date-picker__buttons-wrapper'>
          <Button
            onClick={() => handlePeriodJump(month, year)}
            colorVariant='brand'
            fill='outline'
            disabled={!month || !year}
          >
              Go
          </Button>
        </div>
      </div>
    )
  }
}

const BIMONTHLY_CYCLES = [{label: 'Jan - Feb', value: '03'}, {label: 'March - Apr', value: '05'}, {label: 'May - June', value: '07'}, {label: 'July - Aug', value: '09'}, {label: 'Sep - Oct', value: '11'}, {label: 'Nov - Dec', value: '01'}]
const QUARTERLY_CYCLES = [{label: 'Jan - March', value: '04'}, {label: 'Apr - June', value: '07'}, {label: 'July - Sep', value: '10'}, {label: 'Oct - Dec', value: '01'}]
const MONTHLY_CYCLES = [
  {label: 'Jan', value: '02'},
  {label: 'Feb', value: '03'},
  {label: 'March', value: '04'},
  {label: 'Apr', value: '05'},
  {label: 'May', value: '06'},
  {label: 'June', value: '07'},
  {label: 'July', value: '08'},
  {label: 'Aug', value: '09'},
  {label: 'Sep', value: '10'},
  {label: 'Oct', value: '11'},
  {label: 'Nov', value: '12'},
  {label: 'Dec', value: '01'}
]

export default ReportingPeriodJumper
