const DBNames = {
  Users: '_users',
  GlobalChanges: '_global_changes',
  Replicator: '_replicator',
  IntegratedData: 'integrated-data',
  Documents: 'documents',
  StockCounts: 'stock-count',
  Shipments: 'van-shipments',
  Orders: 'order',
  UserDBSyncCheckpoints: 'user-db-sync-checkpoints',
  SyncPipelineCheckpoints: 'sync-pipeline-checkpoints',
  Messages: 'retailer-messages',
  Ledgers: 'ledger-balances',
  Alerts: 'alerts',
  MasterData: 'master-data'
}

module.exports = {
  DBNames
}
