const stockCountIdToLocationProperties = require('./stock-count-id-to-location-properties')
const shouldTrackBatches = require('./should-track-batches')
const get = require('lodash/get')

const {
  NOT_STARTED,
  IN_PROGRESS,
  COMPLETE
} = require('./utils/progress-statuses')

const reportProgress = (doc, relevantProducts) => {
  // report has no `stock` or `stock: {}` => hasn't been started
  if (!(doc.stock && Object.keys(doc.stock).length)) {
    return NOT_STARTED
  }

  if (doc.submittedAt) {
    return COMPLETE
  }

  const locationId = stockCountIdToLocationProperties(doc._id).id
  // is in progress when `stock` field contains a non empty object
  // and one of the following is true
  const tracksBatches = shouldTrackBatches({
    service: { id: doc.serviceId },
    location: { id: locationId }
  })

  for (let product of relevantProducts) {
    const productStock = doc.stock[product._id]
    // the counts for any product are missing
    if (!productStock) {
      return IN_PROGRESS
    }

    if (!Object.keys(productStock).length) {
      return IN_PROGRESS
    }

    // is missing an `amount` for any non batch tracking product
    if (!tracksBatches) {
      if (typeof get(productStock, 'amount') === 'undefined') {
        return IN_PROGRESS
      }
      continue
    }

    const batches = get(productStock, 'batches', {})
    // is missing `batches` for any batch tracking product
    if (!Object.keys(batches).length) {
      return IN_PROGRESS
    }

    // is missing `amount` for any batch in a batch tracking product
    for (let batchId of Object.keys(batches)) {
      const batch = batches[batchId]
      if (typeof batch.amount === 'undefined') {
        return IN_PROGRESS
      }
    }
  }

  return COMPLETE
}

module.exports = reportProgress
