import { format, isBefore } from 'date-fns'
import get from 'lodash/get'

import { getCountries } from '@fielded/fs-api/lib/service/tools/territory-lookup'

export const roundTo2Decimals = (num) => {
  if (!num || isNaN(num)) return 0
  return Math.round(num * 100) / 100
}

export const byId = (items) => items
  .reduce((itemsByID, element) => {
    // use delve here to support grouping by nested props
    // ex element.location.zone
    itemsByID[get(element, 'id') || get(element, '_id')] = element
    return itemsByID
  }, {})

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

export const dateFormats = {
  long: 'd LLL yyyy',
  longWithTime: 'd LLL yyyy h:mmaaa',
  short: 'd LLL',
  shortWithTime: 'd LLL h:mma',
  shortWithTimeInverted: 'h:mma d LLL',
  shortWithFullMonth: 'd LLLL',
  shortWithDay: 'iii d LLL',
  expiry: 'LL/yy',
  quarter: 'qo',
  snapshotId: 'yyyy-LL-dd',
  printLong: 'iiii d LLLL yyyy',
  printShort: 'dd/LL/yyyy',
  monthLong: 'LLLL',
  monthShort: 'LLL',
  dayOfWeek: 'iiii',
  weekOfYear: 'I',
  invoiceDateFormat: 'dd.LL.yy' // date format for wallet UI (transaction list and pinned payments)
}

export const mapMonthNamesToOptions = (monthNames) =>
  monthNames.map((monthName, index) => ({
    value: index + 1,
    label: `${(index + 1).toString().padStart(2, '0')} - ${monthName}`
  }))

export const monthOptions = () => mapMonthNamesToOptions(monthNames)

export const yearOptions = (start, addNYears) => {
  let startYear = start || new Date().getFullYear()
  let endYear = addNYears ? startYear + addNYears : startYear + 10
  let options = []

  for (let year = startYear; year <= endYear; year += 1) {
    options.push({
      value: year,
      label: year.toString()
    })
  }

  return options
}

export const formatDate = (date, dateFormat = 'short') => {
  // If a batch has no expiry date because it is untracked,
  // return an empty string so there are no obvious display errors
  if (!date) return ''
  return format(date, dateFormats[dateFormat])
}

export const isValidExpiry = (month, year) => {
  const currYear = new Date().getFullYear()
  return (month > 0 && month < 13 && year >= currYear && year < currYear + 40)
}

export const mapProgramsToOptions = (programs) => {
  return programs.map(program => ({
    value: program.id,
    label: program.name
  }))
}

export const mapCountriesToOptions = () => {
  return getCountries().map((val) => ({
    value: val.geoId,
    label: val.name
  }))
}

export const mapProductsToOptions = (products) => {
  return products.map(product => ({
    value: product._id,
    /**
      * We use fullName here to distingiush between e.g.
      * "fullName": "Long Lasting Insecticidal Net - Campaign",
      * "fullName": "Long Lasting Insecticidal Net - Routine",
      * which both have "name": "Long Lasting Insecticidal Nets (LLIN)"
      */
    label: product.fullName
  }))
}

export const getNameFromCollection = (collection, id) => {
  return collection[id] ? collection[id].name : id
}

/*
 * Pluralize a word.
 *
 * Given we currently only need to pluralize english regular nouns, we can use
 * a simple ternary operation.
 */
export const pluralize = (noun, count, plural) => {
  if (!noun) return
  const isPlural = count !== 1

  if (plural) {
    // if a custom plural was passed, for irregular nouns
    return isPlural ? plural : noun
  }

  if (noun.endsWith('y') && isPlural) {
    // if second last char is not a vowel, e.g. in day
    if (/[aeiou]/.test(noun.substring(0, noun.length - 2))) {
      return noun.replace(/.$/, 'ies')
    }
  }

  return !isPlural ? noun : `${noun}s`
}

/*
 * Get the ordinal of any number.
 * eg 6 => 6th and 23 => 23rd etc.
 */
export const getIndexOrdinals = (index) => {
  const ones = index % 10
  const tens = Math.floor(index / 10) % 10

  if (tens !== 1) {
    if (ones === 1) return `${index}st`
    if (ones === 2) return `${index}nd`
    if (ones === 3) return `${index}rd`
  }
  return `${index}th`
}

export const isTodayBeforeSetDate = (date) => {
  if (!date) {
    return false
  }

  const today = new Date()

  return isBefore(today, new Date(date))
}

// There are apps (currently Medbury) that are not Shelf Life but use it underneath. We want them to work same, but have different theme.
export const isShelflifePowered = (config) => {
  return config && config.appBase === 'shelflife'
}

export const isShelflifeReseller = (config) => {
  return isShelflifePowered(config) && !isShelflife(config)
}

export const isShelflife = (config) => {
  return config && config.app === 'shelflife'
}

export const isPsm = (config) => {
  return config && config.app === 'psm'
}

export const isPsmFacilityUser = (config, user) => {
  return isPsm(config) && user.location.level === 'sdp'
}

export const toTitleCase = (str) => {
  return str.split('')[0].toUpperCase() + str.slice(1)
}

export const isValidEmail = (email) => {
  if (!email) {
    return false
  }

  const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,50}$/
  return emailRegex.test(email.trim())
}

export const isValidPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return false
  }

  const phoneNumberRegex = /^[0-9+ ]+$/
  return phoneNumberRegex.test(phoneNumber.trim())
}

// TODO: there will a follow-up PR to rename
// all `masterData` props in the shipments, reports, and analytics apps
// to masterDataShipments, masterDataReports and masterDataAnalytics respectively
export const updateCurrentMasterData = (currentState, masterDataToUpdate) => {
  const newState = { ...currentState }

  if (!newState.masterData) {
    newState.masterData = newState[masterDataToUpdate]
  }
  return newState
}
