const { mergeCouchResponse } = require('../../../tools/utils/couch-response')
const deepGet = require('lodash/get')
const sortBy = require('lodash/sortBy')
const stockCountIdToLocationProps = require('../../../tools/stock-count-id-to-location-properties')
const { OFFLINE_ERROR } = require('../../../utils/offline')

exports.bulkUpsert = bulkUpsert
async function bulkUpsert (state, docs) {
  const keys = docs.map(doc => doc._id)
  const anyExistingDocs = await state.db.allDocs({ keys })
  const upsertDocs = anyExistingDocs.rows.map((existingRow, i) => {
    const doc = docs[i]
    // deleted values still return revs, but posting with those revs
    // will result in conflict. NB, then posting without those revs
    // will give you a later rev then the deleted one :)
    return existingRow.value && !existingRow.value.deleted
      ? Object.assign({}, doc, { _rev: existingRow.value.rev })
      : doc
  })
  const response = await state.db.bulkDocs(upsertDocs)
  return mergeCouchResponse(upsertDocs, response)
}

function checkRange (ranges, id) {
  // We are expected to have circa one check per program
  return !!ranges.find(range => {
    return range.checks.every(check => {
      switch (check.type) {
        case 'match':
          return check.matches.some(match => id.includes(match))
        case 'invertedMatch':
          return !check.matches.some(match => id.includes(match))
        case 'locationMatch':
          const locationId = stockCountIdToLocationProps(id).id
          return check.matches.includes(locationId)
        // case 'greaterThan':
        // case 'lowerThan':
        default:
          throw new Error(`Range: check ${check.type} is not implemented yet`)
      }
    })
  })
}

const inRange = (db, id, getIdDispenser, idCheck) => {
  // Can't be read with allDocs due to a PouchDB bug
  if (/^_local/.test(id)) {
    return { inRange: false, localDoc: true, id: id }
  }

  // Talking to a remote db, assume id is in range
  if (db.adapter === 'http' || db.adapter === 'https') {
    return { inRange: true, id: id }
  }

  return getIdDispenser()
    .then(idsDoc => {
      if (idsDoc.ids.includes(id)) {
        return { inRange: true, id: id }
      }
      /*
       * The 'range' is a series of matchers
       * that can tell if the requested document
       * WOULD HAVE BEEN AVAILABLE if it had been writter:
       * The purpose of this is to limit the number of online requests that we make:
       * if any of the 'sets' matches, it means the doc would have been there
        "range":[
          {
            "set":"program:immunization",
            "program":"program:immunization",
            "checks":[{"type":"match","matches":["2019-W15","2019-W14"]},{"type":"invertedMatch","match":[":program:"]}]
          }
        ]
       */
      if (idsDoc.range) {
        return { inRange: idCheck(idsDoc.range, id), id: id }
      }

      // This means we need to find the doc online
      return { inRange: false, id: id }
    })
    .catch(e => {
      // we're not using id dispenser, assume the doc is local
      return { inRange: true, id: id }
    })
}

const error = (params) => {
  const err = new Error(params.message)
  err.status = params.status
  err.name = params.name
  return err
}

const splitLocalRemote = (db, ids, idCheck) => {
  const ret = {
    localIds: [],
    remoteIds: [],
    // Need to be read separately
    localDocs: []
  }
  // If we're server side, return all these as local
  if (db.adapter === 'https' || db.adapter === 'http') {
    ret.localIds = ids
    return Promise.resolve(ret)
  }

  let pr
  const getIdDispenser = () => {
    if (!pr) {
      pr = db.get('_local/id_dispenser')
    }

    return pr
  }

  return Promise.all(
    ids.map(id => inRange(db, id, getIdDispenser, idCheck))
  )
    .then(responses => {
      responses.forEach(resp => {
        if (resp.inRange) {
          ret.localIds.push(resp.id)
        } else if (resp.localDoc) {
          ret.localDocs.push(resp.id)
        } else {
          ret.remoteIds.push(resp.id)
        }
      })

      return ret
    })
}

const readLocalDocs = (state, ids) => {
  // Make it look like an allDocs response
  return Promise.all(ids.map(id => {
    return state.db.get(id)
      .then(doc => {
        return { id: id, doc: doc, key: id, value: { _rev: doc._rev } }
      })
      .catch(e => {
        return { error: 'not_found', key: id }
      })
  }))
    .then(rows => {
      return {
        rows: rows
      }
    })
}

exports.read = read
async function read (state, ids, options) {
  options = options || {}

  let singleOutput = false
  if (typeof ids === 'string') {
    singleOutput = true
    ids = [ids]
  }

  // You can set include_docs option to false,
  // everything else gives including docs
  const includeDocs = !(options.include_docs === false)

  const config = await splitLocalRemote(state.db, ids, checkRange)

  const promises = []
  if (config.localIds.length) {
    promises.push(state.db.allDocs({
      keys: config.localIds,
      include_docs: includeDocs
    }))
  }

  // This is a work around for a PouchDB bug that allDocs
  // does not read local docs:
  if (config.localDocs.length) {
    promises.push(readLocalDocs(state, config.localDocs))
  }

  // LocalOnly is used in for example ledger balance
  if (!options.localOnly && config.remoteIds.length) {
    promises.push((async function () {
      let result = null
      try {
        if (options.useCache) {
          result = await state.couchDbCache.rest['stock-count'].allDocs({
            keys: config.remoteIds,
            include_docs: includeDocs,
            sparse: true
          })
        } else {
          result = await state.remoteDb.allDocs({
            keys: config.remoteIds,
            include_docs: includeDocs
          })
        }
      } catch (err) {
        const offlineError = err.status === 0 || err.code === 'ESOCKETTIMEDOUT'
        if (offlineError) {
          result = {
            rows: config.remoteIds.map(id => ({
              error: OFFLINE_ERROR,
              key: id
            }))
          }
        } else {
          throw err
        }
      }
      return result
    })())
  } else if (options.localOnly && config.remoteIds.length) {
    promises.push(Promise.resolve({
      rows: config.remoteIds.map(id => ({
        error: 'not_found',
        key: id,
        reason: OFFLINE_ERROR
      }))
    }))
  }

  const resps = await Promise.all(promises)

  const mergedResponse = resps.reduce((sum, resp) => {
    sum.rows = sum.rows.concat(resp.rows)
    return sum
  }, { rows: [] })

  // AllDocs output
  if (!singleOutput) {
    return mergedResponse
  }

  // Retreiving a single doc,
  // make sure we only got one and that it's not an error
  if (mergedResponse.rows.length === 1 && mergedResponse.rows[0].doc) {
    return mergedResponse.rows[0].doc
  }

  // WTF
  if (mergedResponse.rows.length > 1) {
    throw error({
      status: 409,
      name: 'conflict',
      message: 'More than one document was retreived'
    })
  }

  // Couch/pouch returns a row with no error but {value: {deleted: true}}
  // if doc was _deleted
  if (
    mergedResponse.rows.length === 0 ||
      mergedResponse.rows[0].error ||
      deepGet(mergedResponse, 'rows.0.value.deleted')
  ) {
    throw error({
      status: 404,
      name: 'not_found',
      message: 'Document was not found'
    })
  }

  throw error({
    status: 0,
    name: 'unknown_error',
    message: JSON.stringify(mergedResponse)
  })
}

function queryView (db, options) {
  return db.query('api/by-reporting-period-and-location-id', options)
}

// Checks complex keys against the matches from the ID dispenser
// [period, locationId]
function checkRangeForView (ranges, key) {
  const periodId = key[0]
  const locationId = key[1]
  return !!ranges.find(range => {
    return range.checks.every(check => {
      switch (check.type) {
        case 'match':
          // TODO: hack: only check period match, not program match
          // it should be removed or smth
          if (/\d{4}-/.test(check.matches[0])) {
            return check.matches.includes(periodId)
          }

          return true
        case 'invertedMatch':
          return true // not part of the query
        case 'locationMatch':
          return check.matches.includes(locationId)
        // case 'greaterThan':
        // case 'lowerThan':
        default:
          throw new Error(`Range: check ${check.type} is not implemented yet`)
      }
    })
  })
}

exports.readView = readView

async function readView (state, keys, options) {
  options = options || {}

  // You can set include_docs option to false,
  // everything else gives including docs
  const includeDocs = !(options.include_docs === false)

  // LocalOnly is used in
  // for example ledger balance
  if (options.localOnly) {
    return queryView(state.db, {
      keys,
      include_docs: includeDocs
    })
  }

  const config = await splitLocalRemote(state.db, keys, checkRangeForView)

  const promises = []
  // Always make a local check:
  promises.push(queryView(
    state.db,
    {
      keys,
      include_docs: includeDocs
    }
  ))

  if (config.remoteIds.length) {
    promises.push(
      queryView(state.remoteDb, {
        keys: config.remoteIds,
        include_docs: includeDocs
      })
        .catch(err => {
          const offlineError =
            err.status === 0 ||
            err.code === 'ESOCKETTIMEDOUT'

          if (offlineError) {
            return {
              rows: config.remoteIds.map(key => {
                return { error: OFFLINE_ERROR, key: key }
              })
            }
          }

          return Promise.reject(err)
        })
    )
  }

  const [localResponse, remoteResponse] = await Promise.all(promises)
  if (localResponse && remoteResponse) {
    // We want to check if there's something in the local response
    // that was not included in the remote response
    // this can happen when doing a partial count / balance transfer for something outside the reportingPeriod
    const remoteIds = remoteResponse.rows.map(r => r.id)
    localResponse.rows.map(row => {
      if (row.id && !remoteIds.includes(row.id)) {
        remoteResponse.rows.push(row)
      }
    })
    remoteResponse.rows = sortBy(remoteResponse.rows, ['id'])
  }

  return remoteResponse || localResponse
}

exports.write = write
function write (state, doc) {
  if (Array.isArray(doc)) {
    // This was mostly because it seems easier right now,
    // feel free to work on it if you need it
    throw new Error('Report Write Dal only support writing one doc at the time')
  }

  // We're creating a new one,
  // so just write it to local
  if (!doc._rev) {
    return state.db.put(doc)
  }

  return Promise.all([
    splitLocalRemote(state.db, [doc._id], checkRange),
    read(state, doc._id)
  ])
    .then(resp => {
      const config = resp[0]
      const previousDoc = resp[1]
      doc._rev = previousDoc._rev

      // Both '_local' (drafts) docs  and
      // 'these docs are in the offline range'
      // are written like this
      if (config.localIds.length || config.localDocs.length) {
        return state.db.put(doc)
      }

      if (config.remoteIds.length) {
        return state.remoteDb.put(doc)
      }

      return Promise.reject(error({
        status: 409,
        name: 'conflict',
        message: 'Doc does not belong on local or on remote'
      }))
    })
}
