import React from 'react'
import get from 'lodash/get'

import { PriceDisplay } from '@fielded/shared-ui'
import { MEMBERSHIPS } from '@fielded/fs-api/lib/location/tools'

import { formatDate } from '../../../van-shared/utils/utils'
import { CUTOFF_COPY } from '../components/constants'
import { isDateRelevant } from '../../retailer/shipments/helpers'

export const CASHBACK_MULTIPLIER = 2
const CASHBACK_MULTIPLIER_LAST_DAY = '2025-01-31'
const CASHBACK_INELIGIBLE_STATES = ['katsina']

export const diffLists = (a, b) => {
  const setA = a.reduce((set, x) => set.add(x), new Set())
  const setB = b.reduce((set, x) => set.add(x), new Set())

  const removed = a.filter(x => !setB.has(x))
  const added = b.filter(x => !setA.has(x))

  return [added, removed]
}

export const processDeliveryOptions = ({
  isFastDelivery,
  country,
  createdAt,
  deliveryDate,
  customDeliveryDateOption,
  appliedDeliveryFee,
  countryId
}) => {
  const currentDay = new Date()
  const currentHour = currentDay.getHours()
  const cutoffTimes = {
    ng: 12,
    ke: 20
  }

  const getHour = (timestamp) => {
    const inputDate = new Date(timestamp)
    return inputDate.getHours()
  }

  const orderHour = getHour(createdAt)

  let deliveryLabel
  let deliveryDateOptions = []

  if (isFastDelivery) {
    const today = {
      value: formatDate(new Date(), 'snapshotId'),
      label: cutoffTimes[countryId] > currentHour ? CUTOFF_COPY.oneDay : CUTOFF_COPY.twoDay,
      subLabel: <PriceDisplay value={appliedDeliveryFee} country={countryId} currencySymbol />
    }
    deliveryDateOptions.unshift(today)

    deliveryLabel = (country === 'ng' && orderHour < 12) || (country === 'ke' && orderHour < 20) ? CUTOFF_COPY.oneDay : CUTOFF_COPY.twoDay
  } else {
    deliveryDateOptions.push(customDeliveryDateOption)
    deliveryLabel = formatDate(deliveryDate, 'long')
  }
  return { deliveryLabel, deliveryDateOptions }
}

export const shouldSeeCashBack = (location, isPaused) => {
  const state = get(location, 'location.state')
  const membershipType = get(location, 'membership')

  const isExcludedState = CASHBACK_INELIGIBLE_STATES.includes(state)
  const isBasicUser = membershipType === MEMBERSHIPS.BASIC
  const isClassicPAYGOnlyUserPaused = membershipType === MEMBERSHIPS.CLASSIC_PAYG_ONLY && isPaused

  return !isExcludedState && (isBasicUser || isClassicPAYGOnlyUserPaused)
}

export const shouldSeeDoubleCashBack = () => {
  return isDateRelevant(new Date(CASHBACK_MULTIPLIER_LAST_DAY))
}

export const getProcessedCashBack = (cashback) => {
  const withDoubleCashback = shouldSeeDoubleCashBack()
  if (isNaN(cashback) || !withDoubleCashback) {
    return cashback
  }
  return (cashback * CASHBACK_MULTIPLIER)
}
