const keyBy = require('lodash/keyBy')
const get = require('lodash/get')

const getShipmentVolumes = async (shipmentIds, api) => {
  try {
    return (await api.shipment.calculateVolume({ shipmentIds }))
      .reduce((acc, {
        shipment_id: shipmentId,
        total_volume: totalVolume
      }) => {
        return {
          ...acc,
          [shipmentId]: totalVolume
        }
      }, {})
  } catch (error) {
    console.error('Error calculating shipment volume!', error.message)
    return {}
  }
}

const decorateRouteWithDetails = async ({ routes }, api) => {
  if (!routes || routes.length === 0) {
    return []
  }

  const shipments = routes.reduce((acc, route) => {
    return [
      ...acc,
      ...(route.shipments || [])
    ]
  }, [])

  const locationAndFunderIds = shipments.reduce((acc, curr) => {
    acc.locations.add(curr.destination_id)
    acc.funders.add(curr.funder_id)
    return acc
  }, {locations: new Set(), funders: new Set()})

  const locationsAndFunders = await Promise.all([
    ...Array.from(locationAndFunderIds.locations).map(
      (locationId) => api.location.get(locationId)
    ),
    ...Array.from(locationAndFunderIds.funders).map((funderId) =>
      api.funders.get(funderId))
  ])

  const locations = locationsAndFunders.slice(0, locationAndFunderIds.locations.size)
  const locationsById = keyBy(locations, '_id')
  const funders = locationsAndFunders.slice(-1 * locationAndFunderIds.funders.size)

  const shipmentsByIds = keyBy(shipments, 'shipment_id')
  const shipmentVolumes = await getShipmentVolumes(Object.keys(shipmentsByIds), api)

  return (
    routes
      .map((route) => {
        // Collect the shipments that belong to this route
        // and add the calculated load volume to each shipment
        const routeShipments = (route.shipments || []).map((shipment) => {
          const loadVolume = parseFloat(shipmentVolumes[shipment.shipment_id])
          return {
            ...shipment,
            loadVolume: isNaN(loadVolume) ? 0 : loadVolume
          }
        })

        // Collect the unique LGAs, the total load volume and number of stops of the route
        const routeShipmentsData = routeShipments.reduce(
          (acc, shipment) => {
            const destinationId = shipment.destination_id
            const lga = get(locationsById, `${destinationId}.location.lga`, null)
            acc.lga.add(lga)
            acc.stops.add(destinationId)
            acc.totalLoadVolume += shipment.loadVolume
            return acc
          },
          { lga: new Set(), stops: new Set(), totalLoadVolume: 0 }
        )
        return {
          id: route.couchdb_id,
          name: route.name,
          lga: Array.from(routeShipmentsData.lga).join(', '),
          stopsNo: routeShipmentsData.stops.size,
          load: routeShipmentsData.totalLoadVolume,
          startDate: route.start_date,
          endDate: route.end_date,
          approvalDate: route.approval_date ? route.approval_date.toISOString() : null,
          locations,
          funders,
          status: route.status,
          shipments: routeShipments,
          // TODO add this field
          carrier: undefined
        }
      })
  )
}
module.exports = { decorateRouteWithDetails }
