module.exports = pickLastSubmittedReport

const stockCountIdToLocationProperties = require('./stock-count-id-to-location-properties')
const shouldTrackBatches = require('./should-track-batches')

const byReportingPeriodDesc = (a, b) => {
  if (a._id < b._id) {
    return 1
  }
  /* istanbul ignore else */
  if (a._id > b._id) {
    return -1
  }
  // next line not relevant for istanbul since we'll never get the same report twice
  /* istanbul ignore next */
  return 0
}

function pickLastSubmittedReport (reports) {
  reports.sort(byReportingPeriodDesc)

  for (let report of reports) {
    // Not submitted (draft) reports are ignored
    if (!report.submittedAt) {
      continue
    }

    // Reports without stock are also ignored because they're not useful to know what's the initial stock
    const hasStock = report.stock && Object.keys(report.stock).length
    if (!hasStock) {
      continue
    }

    const location = stockCountIdToLocationProperties(report._id)
    const isLocationTrackingBatches = shouldTrackBatches({
      service: { id: report.serviceId },
      location
    })

    // For locations that don't track batches we just need to last submitted report
    if (!isLocationTrackingBatches) {
      return report
    }

    // For locations that track batches we search for the last submitted batched reports
    // If we find a non batched report we can return undefined, since no batched report
    // comes before an unbatched one
    for (let productId of Object.keys(report.stock)) {
      // We don't have product definitions so we can't know which products should track batches
      // as a rough way of checking if this is a batch stock count we just require that _any_
      // product has batches
      const productStock = report.stock[productId]
      if (productStock.batches) {
        return report
      }
    }
    return
  }
}
