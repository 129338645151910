module.exports = `
with
args as (
  select
    $1::uuid as location_id,
    $2::timestamptz[] as event_dates
),
invoice as (
  select
    uuid::uuid as location_id
  from args a
    left join avocado.data_location l on l.uuid = a.location_id::text
  where l.supply_chain_level = 'sdp'
),
lines as (
  select
    e.id as event_id,
    e.event_type,
    case
      when e.delivery_type = 'pay_as_you_sell' then 'consignment'
      else 'postpaid'
    end as order_invoicing_type,
    e.sku,
    e.date,
    e.sl_sold as quantity,
    coalesce(bonus, 0) as bonus,
    l2.shipment_id,
    s.order_id
  from invoice i
  left join avocado.data_stocksummaryevent e on e.location_id = i.location_id
  cross join args a
  cross join lateral (
    select
      string_to_array(e.notes, ' ') as split_notes
  ) l1
  cross join lateral (
    select
      case
        when event_type = 'service_fee' then split_notes[1]::numeric
      end as bonus,
      case
        when event_type in ('buyout', 'topup_buyout', 'delivery') then split_notes[array_length(split_notes, 1)]
      end as shipment_id
  ) l2
  left join avocado.data_shipment s on s.shipment_id = l2.shipment_id
  where e.location_id = a.location_id
    and e.date = any(a.event_dates)
    and sl_sold is not null
    and not exists (
      select il.id
      from avocado.data_invoice_line il
      where event_id = e.id
    )
)
select
  (select to_jsonb(i) from (
    select
      location_id as "locationId"
    from invoice
  ) i) as invoice,
  coalesce(
    (select jsonb_agg(l) from (
      select
        event_id as "eventId",
        event_type as "eventType",
        order_invoicing_type as "orderInvoicingType",
        sku,
        date,
        quantity,
        coalesce(bonus, 0) as bonus,
        order_id as "orderId",
        shipment_id as "shipmentId"
      from lines
    ) l),
    '[]'::jsonb
  ) as "lines"
`
