const get = require('lodash/get')
const uuid = require('uuid')

const removeSpecialChars = (name) => (
  name.replace(/[,.?@%!#'"~<>*();+_-]/g, '').trim()
)

const getParentAccounts = (accountIds, company) => {
  const [accountReceivableId] = accountIds[company].accountReceivableIds
  const loanReceivableId = accountIds[company].loanReceivableId
  return { accountReceivableId, loanReceivableId }
}

const entityToRow = (companyCode, type, entity) => ({
  id: uuid.v4(),
  company_code: companyCode,
  txn_id: entity.Id,
  txn_type: type,
  sync_token: entity.SyncToken,
  raw_data: entity,
  created_at: entity.MetaData.CreateTime,
  updated_at: entity.MetaData.LastUpdatedTime
})

const entitiesToRawRows = (companyCode, entitiesByType) => {
  const map = new Map()
  for (const [type, entities] of Object.entries(entitiesByType)) {
    const transformed = entities.map(
      entity => entityToRow(companyCode, type, entity)
    )
    for (const entity of transformed) {
      // check if in list exists transactions with same id select fresh one.
      // we are using LastUpdatedTime cuz delete statement doesn't have SyncToken
      // set the entity id used to search duplicate in format type:txn_id e.g Invoice:123
      // this id format allows for searching for duplicates on the qbo txn type level instead
      const mapEntityId = `${type}:${entity.txn_id}`
      const duplicateData = map.get(mapEntityId)
      if (duplicateData) {
        const duplicateLastUpdateDate = get(duplicateData, 'raw_data.MetaData.LastUpdatedTime')
        const currentEntityLastUpdateDate = get(entity, 'raw_data.MetaData.LastUpdatedTime')
        if (currentEntityLastUpdateDate > duplicateLastUpdateDate) {
          map.set(mapEntityId, entity)
        }
      } else {
        map.set(mapEntityId, entity)
      }
    }
  }
  return [...map.values()]
}

const getTokenExpireDate = (seconds) => {
  const date = new Date()
  date.setSeconds(new Date().getSeconds() + seconds)
  return new Date(date).toJSON()
}

const isDeleted = (data) => data.status === 'Deleted' || (data.PrivateNote && data.PrivateNote.toLowerCase().includes('voided'))

const getInvoiceLinkedPayments = async (reference, paymentEntity, companyCode, linkedTransactions = []) => {
  const linkedPayments = linkedTransactions.filter(p => p.TxnType === 'Payment').map(p => p.TxnId)
  const batchOperations = linkedPayments.map(txnId => {
    return paymentEntity.get(companyCode, txnId)
  })

  const payments = await Promise.all(batchOperations)
  return payments.find(p => p.PrivateNote === reference)
}

module.exports = {
  entitiesToRawRows,
  getTokenExpireDate,
  isDeleted,
  removeSpecialChars,
  getParentAccounts,
  getInvoiceLinkedPayments
}
