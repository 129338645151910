const { EntityApi } = require('../common')
const PGAdapter = require('./../common/pg-adapter')

const AfricasTalkingAdapter = require('./providers/africas-talking')
const SafaricomAdapter = require('./providers/safaricom')
const MAX_SAFARICOM_BULK_RECEIVERS = 20 // safaricom has a max receipient length of 20 per each bulk message send
// exclusively for sending/fetching data
class ApiAdapter {
  constructor (restAdapter) {
    this.restAdapter = restAdapter
  }

  async getSmsMessages (queryParams = {}) {
    try {
      const smsMessages = await this.restAdapter.get('sms/', queryParams)
      return smsMessages.results
    } catch (e) {
      throw e
    }
  }

  async recordSmsMessage (payload, relation) {
    return this.restAdapter.create('sms/', {...payload, _relation: relation})
  }
}

class SmsApiInterface extends EntityApi {
  constructor (state, restAdapter, logger, africastalkingCredentials, africastalking, isomorphicFetch, pgConnection, safaricomCredentials) {
    const {user = {}} = state
    const smsAdapter = new ApiAdapter(restAdapter)
    super(smsAdapter)
    this.smsProviderCredentials = africastalkingCredentials
    if (africastalkingCredentials && africastalking) {
      this.africastalkingProviderInterface = new AfricasTalkingAdapter(logger, africastalkingCredentials, africastalking)
    }
    if (safaricomCredentials && isomorphicFetch) {
      this.safaricomProviderInterface = new SafaricomAdapter(logger, safaricomCredentials, isomorphicFetch)
    }

    const username = user.name
    const columns = [
      'created_at', 'updated_at', 'message_type', 'message_status', 'location_id'
    ]
    if (pgConnection) {
      this.sms = new PGAdapter(pgConnection, 'avocado.data_sms', username, columns)
    }
  }

  async getSmsMessages (queryParams) {
    return this.adapter.getSmsMessages(queryParams)
  }

  async recordSmsMessage (payload, relation) {
    return this.adapter.recordSmsMessage(payload, relation)
  }

  async sendSms (to, message, country) {
    if (!this.africastalkingProviderInterface) {
      throw new Error('Error: api constructor requires africastalkingCredentials and africastalking library in order to send SMS')
    }

    // in case if safaricom not set send thru africastalking
    if (country === 'ke' && this.safaricomProviderInterface && to && to.length <= MAX_SAFARICOM_BULK_RECEIVERS) {
      return this.safaricomProviderInterface.sendSms(to, message)
    }
    return this.africastalkingProviderInterface.sendSms(to, message)
  }

  isOptedInToSms (location) {
    return location.test_config && location.test_config.ab_sms_alerts
  }
}

module.exports = SmsApiInterface
