module.exports = toStockCountId

function toStockCountId (params) {
  const {
    location,
    service,
    reportingPeriod
  } = params

  /* istanbul ignore if */
  if (!location) {
    throw new Error('location parameter is required')
  }
  if (!service) {
    throw new Error('service parameter is required')
  }
  if (!reportingPeriod) {
    throw new Error('reportingPeriod parameter is required')
  }

  let periodPrefix
  switch (service.program.reportingPeriod) {
    case 'weekly':
      periodPrefix = 'week'
      break
    case 'bimonthly':
      periodPrefix = 'bimonth'
      break
    case 'quarterly':
      periodPrefix = 'bimonth'
      break
    case 'monthly':
      periodPrefix = 'month'
      break
    default:
      throw new Error('Unsupported reporting period type: ', service.program.reportingPeriod)
  }
  return `${location}:${periodPrefix}:${reportingPeriod}:${service.id}`
}
