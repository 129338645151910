module.exports = shouldTrackBatches

const getSubmitProperties = require('./get-submit-properties')
const get = require('lodash/get')

function shouldTrackBatches (params) {
  const {
    location,
    service
  } = params

  const serviceId = service && service.id

  // For PSM warehouses
  if (hasBatchTrackingServicesProp(location, serviceId)) {
    return true
  }

  // The `service` argument is coming from the `programs` doc.
  // `service.tracksBatches` is set to true for all PSM
  // warehouse services.
  // Currently, for all active warehouses `location.batchTrackingServices.all`
  // is also set to true, so this clause and the previous one are checking the
  // same thing.
  // If no location is passed, `shouldTrackBatches` behaves
  // in a strange way, returning `true` regardless of the value
  // of `service.tracksBatches`.
  if (service && service.tracksBatches) {
    return true
  }

  // I am leaving this clause here to keep the same behaviour as before,
  // but I think we could actually make `location` a mandatory parameter.
  // Right now it is passed everywhere shouldTrackBatches is called.
  if (!location) {
    return true
  }

  // At this point the content of `serviceId` does not matter:
  // - if serviceId is defined it returns the default config
  // - if serviceId is not defined it returns the default config
  // for the location level
  // This doesn't seem to be made on purpose, it looks like
  // some left over VAN code that is accidentally working (or not?)
  return getSubmitProperties(serviceId, location.id).submitsBatchedCounts
}

// This property (`batchTrackingServices`) is used in PMS warehouses
function hasBatchTrackingServicesProp (location, serviceId) {
  if (get(location, `batchTrackingServices.all`)) return true
  if (get(location, `batchTrackingServices.${serviceId}`)) return true
}
