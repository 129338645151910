import React, { Fragment, useState } from 'react'

import { Button, Text, Table } from '@fielded/shared-ui'
import { getProgramReadableName } from '@fielded/fs-api/lib/program/tools'
import { ExclamationTriangle } from '@fielded/shared-ui/src/icons'

import { formatDate, getNameFromCollection } from '../../../../../../van-shared/utils/utils'
import LMDStatusTag from '../components/LMDStatusTag'
import LMDListEmptyInfoBox from './LMDListEmptyInfoBox'
import LMDListTableModal from './LMDListTableModal'
import getSortDirection from '../../../../../../van-shared/hooks/getSortDirection'

const LMDListTable = ({
  history,
  programs,
  appPrograms,
  locationsById,
  fundersById,
  shipments,
  view,
  loadingShipments
}) => {
  const [viewedShipment, setViewedShipment] = useState(null)
  const [sortBy, setSortBy] = useState()
  const [sortDirection, setSortDirection] = useState()

  const tableColumns = [
    {
      key: 'shipmentNo',
      header: 'ID',
      dataKey: 'shipmentNo'
    },
    {
      key: 'originId',
      header: 'Source',
      dataKey: 'origin.id',
      ellipsis: true,
      cellDataGetter: ({rowData}) => rowData,
      cellRenderer: ({ cellData }) =>
        <Text bold>
          {getNameFromCollection(locationsById, cellData.origin.id)}
        </Text>
    },
    {
      key: 'destinationId',
      header: 'Destination',
      dataKey: 'destination.id',
      ellipsis: true,
      cellDataGetter: ({rowData}) => rowData,
      cellRenderer: ({ cellData }) =>
        <Text bold>
          {getNameFromCollection(locationsById, cellData.destination.id)}
        </Text>
    },
    {
      key: 'funderId',
      header: 'Funder',
      dataKey: 'funder.id',
      cellDataGetter: ({rowData}) => rowData,
      cellRenderer: ({ cellData }) => getNameFromCollection(fundersById, cellData.funder.id)
    },
    {
      key: 'programId',
      header: 'Program',
      dataKey: 'programId',
      width: 110,
      cellRenderer: ({ cellData }) => getProgramReadableName(cellData, appPrograms)
    },
    {
      key: 'createdAt',
      header: 'Created on',
      dataKey: 'createdAt',
      width: 120,
      cellRenderer: ({ cellData }) => formatDate(cellData, 'long')
    },
    {
      key: 'status',
      header: 'Status',
      dataKey: 'status',
      align: 'right',
      cellDataGetter: ({rowData}) => rowData,
      cellRenderer: ({ cellData }) => {
        return (
          <div className='vs-u-column vs-u-align-end vs-u-gap-half'>
            <LMDStatusTag status={cellData.status} />
            <Text size='tiny'>{`on ${formatDate(cellData.updatedAt, 'long')}`}</Text>
          </div>
        )
      }
    },
    {
      key: 'deliveryAlerts',
      dataKey: 'deliveryAlerts',
      header: 'Alert information',
      hideHeader: true,
      cellClassName: 'last-mile-deliveries__warning-icon-wrapper',
      cellDataGetter: ({rowData}) => rowData,
      cellRenderer: ({ cellData }) => {
        if (!cellData.deliveryAlerts.length) return null
        return (
          <Button
            icon={<ExclamationTriangle />}
            className='last-mile-deliveries__warning-icon'
            onClick={(e) => onOpenModal(e, cellData)}
          />
        )
      }
    }
  ]

  const handleSort = (columnName, direction) => {
    const newSortDirection = getSortDirection(sortBy, columnName, direction)
    setSortDirection(newSortDirection)
    setSortBy(columnName)
  }

  const onOpenModal = (event, shipment) => {
    event.stopPropagation()
    setViewedShipment(shipment)
  }

  const onCloseModal = () => {
    setViewedShipment(null)
  }

  return (
    <Fragment>
      <Table
        entries={!loadingShipments && shipments}
        onHandleSort={handleSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        rowClassName='last-mile-deliveries__table-row'
        rowClassNameCondition={(shipment) => shipment.deliveryAlerts.length}
        onRowClick={(shipment) => history.push(`/shipments/last-mile-deliveries/${view}/details/${shipment.snapshotId}`)}
        noRowsContent={<LMDListEmptyInfoBox loading={loadingShipments} />}
        columns={tableColumns}
      />

      <LMDListTableModal
        modalVisibility={!!viewedShipment}
        shipment={viewedShipment}
        onCloseModal={onCloseModal}
      />
    </Fragment>
  )
}

export default LMDListTable
