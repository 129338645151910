import { createSlice } from '@reduxjs/toolkit'
import {
  getIsUsingFlagsStatus,
  getTimestampForNextApiCall,
  setIsUsingFlagsStatus
} from '../../../../common/utils'

// 24h
const MINUTES_TO_ADD = 1440

const initialState = {
  timestampForTheNextApiCall: getTimestampForNextApiCall(MINUTES_TO_ADD),
  deviceId: '',
  usingFlags: getIsUsingFlagsStatus()
}

export const userSessionSlice = createSlice({
  name: 'userSession',
  initialState,
  reducers: {
    timestampUpdate: (state) => {
      state.timestampForTheNextApiCall = getTimestampForNextApiCall(MINUTES_TO_ADD)
    },
    deviceIdUpdate: (state, action) => {
      state.deviceId = action.payload
    },
    flagUsageUpdate: (state, action) => {
      const usingFlags = action.payload
      setIsUsingFlagsStatus(usingFlags)
      state.usingFlags = usingFlags
    }
  }
})

export const { timestampUpdate, deviceIdUpdate, flagUsageUpdate } = userSessionSlice.actions
export const selectTimestampToNextSessionCall = (state) => state.userSession.timestampForTheNextApiCall
export const selectDeviceId = (state) => state.userSession.deviceId
export const selectUsingFlags = (state) => state.userSession.usingFlags

export default userSessionSlice.reducer
