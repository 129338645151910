import orderBy from 'lodash/orderBy'
import { isSameDay, isFuture } from 'date-fns'

import { ROUTINE, TOPUP } from '@fielded/fs-api/lib/shipment/tools/planning-types'
import { batchIdToProductId } from '@fielded/fs-api/lib/batch/tools'
import { PAY_AS_YOU_SELL, PAY_ON_DELIVERY } from '@fielded/shared-ui/src/utils/subscription-type-constants'
import { getPrice } from '@fielded/fs-api/lib/product/tools'

import { isTopUp, isCR, isReturnShipment, isPendingTopup } from '../../../common/utils/shipment'
import { RETAILER_SERVICES } from '../../../van-shared/components/ServiceCard/service-constants'
import { SERVICE_DATA } from '../common/service-constants'
import { getProcessedCashBack } from '../../facility-products/common/utils'

export function createShipmentTitle (shipment, isReturn, appName = 'Shelf Life') {
  if (isCR(shipment)) {
    return `Collection by ${appName}`
  }

  if (isTopUp(shipment)) {
    return `Top-up from ${appName}`
  }

  if (isReturn) {
    return `Return to ${appName}`
  }

  if (isShipmentProvisional(shipment)) {
    return 'Plan for upcoming delivery'
  }

  return `Arrival from ${appName}`
}

export function createShipmentStatus (shipment, isReturn) {
  let statusText, shipmentStatus
  if (shipment.status === 'request' ||
    shipment.status === 'in-process') {
    statusText = 'Pending'
  }

  if (isShipmentCancelled(shipment)) {
    statusText = 'Cancelled'
    shipmentStatus = 'cancelled'
  }

  if (shipment.status === 'new') {
    statusText = 'Scheduled'
  }

  if (shipment.status === 'sent') {
    statusText = (isCR(shipment)) ? 'Sent' : 'Incoming'
  }

  if (shipment.status === 'arrived') {
    statusText = 'Incoming'
  }

  if (isShipmentComplete(shipment)) {
    statusText = 'Received'
  }

  if (isCR(shipment) || isReturn) {
    shipmentStatus = 'distribution'
  }

  if (isShipmentProvisional(shipment)) {
    statusText = 'Provisional'
    shipmentStatus = 'provisional'
  }

  return { text: statusText, type: shipmentStatus }
}

export function isShipmentComplete (shipment) {
  return shipment.status === 'received'
}

export function isShipmentCancelled (shipment) {
  return shipment.status === 'cancelled'
}

export const isShipmentProvisional = (shipment) => {
  if (!shipment) {
    return false
  }

  return (
    shipment.status === 'in-process' && [ROUTINE, TOPUP].includes(shipment.planningType)
  )
}

export function getShipmentProductIds (shipment) {
  return [...new Set(
    Object.keys(shipment.counts).map(batchId => batchIdToProductId(batchId))
  )]
}

export function calculateTotals (selection, productsMap, options = {}) {
  const { quantityProp = 'quantity', priceDate = new Date().toJSON() } = options

  const products = Object.entries(selection)

  const result = products.reduce((acc, [id, orderItem]) => {
    const product = productsMap instanceof Map ? productsMap.get(id) : productsMap[id]

    if (!product) return acc

    const qty = orderItem[quantityProp] || 0
    const price = getPrice(product.prices, priceDate) || Number(product.unitPrice)
    const financingMarkup = getPrice(product.prices, priceDate, 'financingMarkup') || 0
    const cashbackValue = qty * financingMarkup
    acc.cashback = acc.cashback + cashbackValue

    const productValue = qty * price
    acc.subtotal = acc.subtotal + productValue
    const vatMultiplier = product.hasVat ? product.vats[0].vat / 100 : 0
    const productVatValue = productValue * vatMultiplier
    acc.vat = acc.vat + productVatValue
    acc.total = acc.total + productValue + productVatValue

    return acc
  }, { vat: 0, subtotal: 0, total: 0, cashback: 0 })

  const roundedCashback = Math.round(result.cashback * 100) / 100
  result.cashback = getProcessedCashBack(roundedCashback)

  return result
}

// these 3 statuses means we don't have a shipment yet, it's an order:
const PendingStatuses = ['request', 'in-process', 'cancelled']

export function getSnapshotId (service) {
  const hasPendingStatus = PendingStatuses.includes(service.status)
  const snapshotId = hasPendingStatus ? service.orderId : service.snapshotId

  return snapshotId
}

function getShipmetServiceUrl (service) {
  const snapshotId = getSnapshotId(service)
  const url = `/retailer/delivery-detail/${snapshotId}`
  return url
}

export function getShipmentServiceDate (service) {
  // If it has been received, list with receive date, not scheduled date
  if (service.snapshotDates && service.snapshotDates.received) {
    return service.snapshotDates.received
  }
  return service.date || service.orderDeliveryDate
}

function getShipmetServiceProductTypes (service) {
  const countsList = Object.values(service.counts)
  let withODProducts, withPAYSProducts

  // If shipment has counts object,
  // we want to see if any shipped products are with payment type OD or PAYS to show the icon.
  if (countsList.length > 0) {
    withODProducts = countsList.some((product) => product.paymentType === PAY_ON_DELIVERY)
    withPAYSProducts = countsList.some((product) => product.paymentType === PAY_AS_YOU_SELL)
  }

  return { withODProducts, withPAYSProducts }
}

function getShipmetServiceStatus (service) {
  let status = 'Scheduled'

  if (PendingStatuses.includes(service.status)) {
    status = 'Pending'
  }

  if (isShipmentProvisional(service)) {
    status = 'Provisional'

    if (service.loanLimitExceeded) {
      status = 'Action required'
    }
  }

  if (isShipmentCancelled(service)) {
    status = 'Cancelled'
  }

  return status
}

const { DELIVERY, COLLECTION, COUNT, PROVISIONAL, CANCELLED } = RETAILER_SERVICES
const RETURN = 'return'

// COUNTS

function addCountServiceDetails (service) {
  // this method will work with count cause we have a counts key ie {counts: nextCount.draft.stock}
  // const productsCount = getShipmentProductIds(service).length
  const productsCount = Object.keys(service.counts || service.stock).length
  const status = getShipmetServiceStatus(service)
  const isScheduledCount = !!service.scheduledAt

  service.serviceDetails = {
    serviceType: COUNT,
    date: service.submittedAt || service.scheduledAt,
    status,
    productsCount,
    url: isScheduledCount ? null : `/retailer/inventorystatement/${service._id}`
  }

  return service
}

// SHIPMENTS

function addShipmentServiceDetails (user, service) {
  const productsCount = getShipmentProductIds(service).length
  const date = getShipmentServiceDate(service)
  const { withODProducts, withPAYSProducts } = getShipmetServiceProductTypes(service)
  const url = getShipmetServiceUrl(service)
  const status = getShipmetServiceStatus(service)

  const shipmentDetails = { status, productsCount, date, withODProducts, withPAYSProducts, url }

  // DELIVERY => new delivery & top-ups
  if (!isReturnShipment(user, service) || isTopUp(service) || (
    service.status === 'new' && service.planningType === ROUTINE)) {
    service.serviceDetails = { ...shipmentDetails, serviceType: DELIVERY }
  }

  // COLLECTION => collection & returns
  if (isCR(service) || isReturnShipment(user, service)) {
    service.serviceDetails = { ...shipmentDetails, serviceType: RETURN }
  }

  if (isShipmentProvisional(service)) {
    service.serviceDetails = { ...shipmentDetails, serviceType: PROVISIONAL }
  }

  if (isShipmentCancelled(service)) {
    service.serviceDetails = { ...shipmentDetails, serviceType: CANCELLED }
  }

  return service
}

export function addServiceDetails (user, service) {
  return service.type === 'stockCount' ? addCountServiceDetails(service) : addShipmentServiceDetails(user, service)
}

export const getHeaderDetails = (service, type, shortFormat = false) => {
  const serviceType = type === RETURN ? COLLECTION : type
  const isTopUpService = isTopUp(service)
  const isCRService = isCR(service)

  const { service: serviceTitle, icon } = SERVICE_DATA[serviceType]
  let title = shortFormat ? serviceTitle : `next ${serviceTitle}`

  // we need this check for the list header in service history (specifically for counts).
  title = (type === COUNT && shortFormat) ? `stock ${COUNT}` : title

  switch (serviceType) {
    case COUNT:
      return { title, icon }

    case DELIVERY:
    case CANCELLED:
      return { title: isTopUpService ? 'top-up' : title, icon }

    case COLLECTION:
      return { title: isCRService ? serviceType : RETURN, icon }

    case PROVISIONAL:
      return { title: `${isTopUpService ? 'top-up' : serviceTitle} plan`, icon }

    default:
      return { title: '', icon: null }
  }
}

export const filterDisplayList = (list, type) => {
  // all cancelled services go under deliveries
  const cancelledList = list.filter(({ serviceDetails }) => serviceDetails.serviceType === CANCELLED)

  // this is needed for modified service types (ie delivery-topup & return-collection)
  const [serviceType, modifier] = type.split('-')
  let newList = list.filter(({ serviceDetails }) => serviceDetails.serviceType === serviceType)

  switch (serviceType) {
    case DELIVERY:
      newList = newList.concat(cancelledList).filter(service => (
        modifier ? isTopUp(service) : !isTopUp(service)
      ))
      return orderBy(newList, ['submittedAt'], ['desc'])

    case RETURN:
    case COLLECTION:
      newList = newList.filter(service => (
        modifier ? isCR(service) : !isCR(service)
      ))
      return newList

    case COUNT:
      return newList

    default:
      return list
  }
}

export const isDateRelevant = (date) => {
  return isSameDay(new Date(), date) || isFuture(date)
}

export const isTopUpRelevant = (shipment) => {
  return ((isPendingTopup(shipment)) && isDateRelevant(shipment.createdAt))
}
