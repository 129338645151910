const snakeCase = require('lodash/snakeCase')
const EntityApi = require('./entity-api')
const { singularize } = require('../utils/pluralize')

const supportedActionNames = [
  'list',
  'get',
  'create',
  'update',
  'delete'
]

/**
 * Proxified Entity API for dynamically generating method proxies, so an API call will trigger an HTTP request to agave
 * instead of e.g. trying to directly connect to PostgreSQL if there's no PostgreSQL connection.
 * Extends the EntityApi and allows for the dynamic creation of proxy API methods based on provided method names.
 * @class
 * @extends EntityApi
 */
class ProxifiedEntityApi extends EntityApi {
  constructor (entityName, methodsNeedingProxy, needsProxy, adapter, agaveAdapter) {
    super(adapter)
    this.agaveAdapter = agaveAdapter
    if (needsProxy) {
      this.proxifyMethods(entityName, methodsNeedingProxy)
    } else {
      // Return the routes so our HTTP REST API can automatically link routes to api methods
      this.proxyAutoRoutes = this.getURLPaths(entityName, methodsNeedingProxy)
    }
  }

  getProxyConfig (entityName, methodName, restAction) {
    // Split snake case method name into action and sub-entity parts
    const [actionNameCandidate, ...subEntityCandidateParts] = snakeCase(methodName).split('_')

    if (!supportedActionNames.includes(actionNameCandidate)) {
      // If the action is not supported, the method name is the sub-entity name
      // and the action is a GET if not provided a custom restAction
      return {
        restAdapterAction: restAction || 'GET',
        pathToEndpoint: `${entityName}/${snakeCase(methodName).split('_').join('-')}`
      }
    }

    // If the action is supported, we take that as the restAdapterAction
    // and we use the rest of the name as the sub-entity name
    // if the action is list, we convert the sub-entity name to singular form (e.g. listLocations should be a GET on /location/)
    // We target this form: "http://dev.psm.localhost:3000/agave/v1/shipment/last-mile-delivery/?limit=500"
    const subEntityOrMethodName = actionNameCandidate === 'list'
      ? singularize(subEntityCandidateParts.join('-')) || ''
      : subEntityCandidateParts.join('-')

    return {
      restAdapterAction: actionNameCandidate || restAction,
      pathToEndpoint: `${entityName}/${subEntityOrMethodName}`
    }
  }

  proxify (func, entityName, methodName, restAction) {
    this[methodName] = async (params) => {
      // Build path to endpoint
      const {restAdapterAction, pathToEndpoint} = this.getProxyConfig(entityName, methodName, restAction)
      return this.agaveAdapter[restAdapterAction](pathToEndpoint, params)
    }
  }

  proxifyMethods (entityName, methodsNeedingProxy) {
    methodsNeedingProxy.forEach(method => {
      const {methodName, restAction} = typeof method === 'object'
        ? method
        : {methodName: method}
      this.proxify(this[methodName], entityName, methodName, restAction)
    })
  }

  getURLPaths (entityName, methodsNeedingProxy) {
    const paths = methodsNeedingProxy.map(methodName => {
      const config = this.getProxyConfig(entityName, methodName)
      return config && config.pathToEndpoint
    })
    return paths
  }
}

module.exports = ProxifiedEntityApi
