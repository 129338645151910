const { toDraft, fromDraft } = require('../../tools')
const docToReport = require('./doc-to-report')

module.exports = async (state, {
  reportId,
  service = null, // optional, pass if available
  period = null, // optional, pass if available
  entityOptions = null // {addProducts: false, addFields: false}
}) => {
  let doc
  try {
    const draftId = toDraft(reportId)
    doc = await state.dal.report.read(state, draftId)
    doc._id = fromDraft(doc._id)
  } catch (e) {
    if (e.name === 'not_found') {
      return null
    }
    throw e
  }
  return docToReport(state, {doc, service, period, entityOptions})
}
