import { shouldTrackBatches } from '@fielded/fs-api/lib/tools'
import { VAN_SERVICE } from './program'
import { isShelflifePowered, isPsm } from '../../../van-shared/utils/utils'
export const IS_DEV = process.env.NODE_ENV === 'development'

export const byId = (collection, prop = 'id') => collection.reduce((
  index,
  element
) => {
  if (index) {
    index[element[prop]] = element
  }
  return index
}, {})

export const capitalize = string =>
  `${string[0].toUpperCase()}${string.slice(1)}`

export const getLocationType = location => {
  const locationParts = location.split(/:/)
  const locationType = locationParts.length > 1
    ? locationParts[locationParts.length - 2]
    : locationParts[0]

  return locationType
}

export const getMyLocations = rootLocation => {
  const locations = ['national', 'zone', 'state', 'lga']
  const currentLocation = getLocationType(rootLocation)
  const index = locations.indexOf(currentLocation)

  if (index === -1) {
    return locations
  }

  return locations.splice(index)
}

/**
 * gets the parent location of the current location.
 * @param {number} index How down the generation you want to go. This should be in multiples of 2
 * @param {string} location The current child location eg zone:nc:state:benue
 */
export const getParentLocation = (index, location) => {
  const locationParts = location.split(/:/)
  let childRoute = ''
  for (let i = 0; i < index; i++) {
    if (i === 0) {
      childRoute += locationParts[i]
      continue
    }
    childRoute += `:${locationParts[i]}`
  }
  return childRoute
}

const is = (type) => (input) => {
  return Object.prototype.toString.call(input) === `[object ${type}]`
}

export const isNumber = is('Number')
export const isString = is('String')

export const isNegativeValue = (input) => (input < 0)

// For use on e.g. onKeyDown on number fields that should
// not allow user to enter non-numeric input, like - or .
export const restrictNumberInput = event => {
  // Should do nothing if the default action has been cancelled
  if (event.defaultPrevented) return

  const unwantedKeys = '-eE.,'
  let isUnwantedKeyInput = false

  if (event.key !== undefined) {
    isUnwantedKeyInput = unwantedKeys.indexOf(event.key) >= 0
  }
  // KeyboardEvent.keyIdentifier or event.keyCode are not included
  // since this is just an assistive function to set user expectations
  // and key is covered in our main browsers for this use case.
  // https://caniuse.com/#feat=keyboardevent-key
  // If event.key is not present, we simple ignore.

  if (isUnwantedKeyInput) event.preventDefault()
  return !isUnwantedKeyInput
}

export const isFraction = (input) => {
  return input % 1 !== 0
}

export const promiseSerial = funcs => {
  return funcs.reduce((promise, fn) => (
    promise.then(results => fn().then(res => results.concat(res)))
  ), Promise.resolve([]))
}

export const sortProductByIndices = (serviceId, report, productsById) => {
  return Object.keys(report.stock)
    .sort((id1, id2) => {
      // Sort products by index from MD. Safe guard for when an index is not found.
      const a = productsById[id1]
      const b = productsById[id2]
      try {
        const i1 = a.services.find(s => s.id === serviceId).index
        const i2 = b.services.find(s => s.id === serviceId).index
        return i1 < i2 ? -1 : 1
      } catch (e) {
        return -1
      }
    })
}

export const sortProductSL = (serviceId, report, productsById) => {
  return Object.keys(report.stock)
    .filter(productId => {
      return !report.stock[productId].skipCount
    })
    .sort((id1, id2) => {
      // Sort products using product type name
      const a = productsById[id1]
      const b = productsById[id2]
      try {
        const i1 = a.productType
        const i2 = b.productType
        return i1 < i2 ? -1 : 1
      } catch (e) {
        return -1
      }
    })
}

/*
get date in a friendable format
*/
export const toFriendableDate = (date) => {
  return date.toLocaleString('en-NG', {
    day: 'numeric',
    month: 'short',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  })
}

/**
 * Return the batchNumber for a given delimited batchId in the e.g
 * 'foo:bar:n:batchId' where batchId is the last part.
 *
 * @param {string} batchId
 */
export const batchNumberFromId = (batchId, delimiter = ':') => batchId.split(delimiter).reverse()[0]

// Locations which use simple form stock counts are LGAs in VAN
export const usesSimpleForm = (serviceId, location) => {
  const tracksBatches = shouldTrackBatches({
    service: { id: serviceId },
    location
  })
  return serviceId === VAN_SERVICE && !tracksBatches
}

export { isShelflifePowered, isPsm }
