const { get: getLocation } = require('../../../location/api/read/get')
const { list: listFunders } = require('../../../funders/api/read/list')

// Heads up: these validates do async calls.
const validateFunder = async (state, location, locationFunders) => {
  if (!locationFunders) throw new Error('No selected funder')

  const funders = await listFunders(state)
  const program = location.programs[0].id
  const programFunders = funders.filter(funder => Object.keys(funder.programs).includes(program))
    .map(funder => { return funder._id })
  const verifySelectedFunder = locationFunders.every(funder => {
    return programFunders.indexOf(funder) >= 0
  })
  if (!verifySelectedFunder) {
    return 'Selected funder(s) does not match program'
  }
}

const validateLocation = async (state, user) => {
  const locationId = user.location.id
  const selectedProgram = user.programs
  const selectedFunder = user.funders || []
  const locationDoc = await getLocation(state, locationId)

  // NB: a user's location is not required to be a
  // physical location, so only say invalid if the location does not exist.
  if (locationDoc && locationDoc.additionalData.isPSMWarehouse) {
    if (selectedProgram.length > 1) {
      return 'A warehouse location can not be assigned multiple programs'
    }

    if (selectedProgram.toString() !== locationDoc.programs[0].id) {
      return 'Selected warehouse location program does not match selected program'
    }
    return validateFunder(state, locationDoc, selectedFunder)
  }
}

exports.validateLocation = validateLocation
