// TODO: this should probably all change to be dynamic based on territory
// table & require sales to enter an explicit territory
// issue ticket: https://github.com/fielded/van-orga/issues/4568

const TERRITORIES_MAP = [
  {
    service: 'program:shelflife:service:pharmaceuticals',
    shortServiceId: 'pharmaceuticals',
    geoId: 'country:ng:state:fct',
    aliases: ['abuja', 'fct'],
    rdsMarketAlias: 'fct',
    state: 'fct',
    name: 'FCT',
    packPointId: 'country:ng:state:fct:sdp:shelf-life-fct-held',
    isProductIDMemberFunction: (productId) => codeRange(productId, 0, 1000) || productId.startsWith('product:FCT-'),
    relatedTerritories: ['program:shelflife:service:nasarawa'],
    rdsIdPrefix: '',
    freshsalesId: 30000001600,
    primaryResupplier: 'country:ng:state:lagos:sdp:mebs-facility-lagos'
  },
  {
    service: 'program:shelflife:service:nasarawa',
    shortServiceId: 'nasarawa',
    geoId: 'country:ng:state:nasarawa',
    aliases: ['nasarawa', 'nassarawa', 'nas'],
    rdsMarketAlias: 'nas',
    rdsMarketName: 'FCT', // used in avocado.data_location
    state: 'nasarawa',
    name: 'Nasarawa',
    packPointId: 'country:ng:state:fct:sdp:shelf-life-fct-held',
    isProductIDMemberFunction: (productId) => codeRange(productId, 3000, 4000) || productId.startsWith('product:NAS-'),
    relatedTerritories: ['program:shelflife:service:pharmaceuticals'],
    rdsIdPrefix: '',
    freshsalesId: 30000001600 // This is the same Freshsales territory as FCT,
  },
  {
    service: 'program:shelflife:service:lagos',
    shortServiceId: 'lagos',
    geoId: 'country:ng:state:lagos',
    aliases: ['lagos', 'lgs'],
    rdsMarketAlias: 'lgs',
    state: 'lagos',
    name: 'Lagos',
    packPointId: 'country:ng:state:lagos:sdp:mebs-facility-lagos',
    isProductIDMemberFunction: (productId) => codeRange(productId, 5000, 6000) || productId.startsWith('product:LGS-'),
    relatedTerritories: ['program:shelflife:service:abeokuta', 'program:shelflife:service:cedar', 'program:shelflife:service:demo', 'program:shelflife:service:medbury'],
    rdsIdPrefix: '',
    freshsalesId: 30000004738
  },
  {
    service: 'program:shelflife:service:port-harcourt',
    shortServiceId: 'port-harcourt',
    geoId: 'country:ng:state:rivers',
    aliases: ['port-harcort', 'port-harcourt', 'port harcort', 'ph', 'phc', 'rivers'],
    rdsMarketAlias: 'ph',
    rdsMarketName: 'PH', // used in avocado.data_location
    state: 'rivers',
    name: 'Rivers',
    packPointId: 'country:ng:state:rivers:sdp:shelf-life-ph-held',
    isProductIDMemberFunction: (productId) => codeRange(productId, 6000, 7000) || productId.startsWith('product:PHC-'),
    relatedTerritories: ['program:shelflife:service:akwa-ibom'],
    rdsIdPrefix: '',
    freshsalesId: 30000001772,
    primaryResupplier: 'country:ng:state:lagos:sdp:mebs-facility-lagos'
  },
  {
    service: 'program:shelflife:service:kenya',
    shortServiceId: 'kenya',
    geoId: 'country:ke:state:nairobi',
    aliases: ['nairobi', 'kenya', 'nbo'],
    rdsMarketAlias: 'NBO',
    state: 'nairobi',
    name: 'Nairobi',
    returnShipmentDestinationId: 'country:ke:state:nairobi:sdp:shelf-life-nairobi-held',
    packPointId: 'country:ke:state:nairobi:sdp:shelf-life-nairobi-held',
    isProductIDMemberFunction: (productId) => codeRange(productId, 9000, 12000) || productId.startsWith('product:NBO-'),
    relatedTerritories: ['program:shelflife:service:coastal', 'program:shelflife:service:nyanza', 'program:shelflife:service:south-rift', 'program:shelflife:service:north-rift', 'program:shelflife:service:antara', 'program:shelflife:service:penda', 'program:shelflife:service:central-province'],
    rdsIdPrefix: '',
    freshsalesId: 30000004737
  },
  {
    service: 'program:shelflife:service:lower-east',
    shortServiceId: 'lower-east',
    geoId: 'country:ke:state:lower-east',
    packPointId: 'country:ke:state:nairobi:sdp:shelf-life-nairobi-held',
    aliases: ['lower-east']
    // isProductIDMemberFunction none! needed for when lower-east goes live
    // rdsMarketAlias also needed for marketDataRecommendations
  },
  {
    service: 'program:shelflife:service:machakos',
    shortServiceId: 'machakos',
    geoId: 'country:ke:state:machakos',
    packPointId: 'country:ke:state:nairobi:sdp:shelf-life-nairobi-held',
    aliases: ['machakos']
    // isProductIDMemberFunction none! needed for when machakos goes live
    // rdsMarketAlias also needed for marketDataRecommendations
  },
  {
    service: 'program:shelflife:service:abeokuta',
    shortServiceId: 'abeokuta',
    geoId: 'country:ng:state:ogun',
    hq: 'country:ng:state:ogun:sdp:sl-abeokuta-hq',
    aliases: ['ogun', 'abeokuta'],
    rdsMarketAlias: 'ogun',
    state: 'ogun',
    name: 'Ogun',
    packPointId: 'country:ng:state:lagos:sdp:mebs-facility-lagos',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:OGUN-'),
    relatedTerritories: ['program:shelflife:service:lagos', 'program:shelflife:service:cedar', 'program:shelflife:service:demo', 'program:shelflife:service:medbury'],
    rdsIdPrefix: 'OGUN',
    freshsalesId: 30000004738
  },
  {
    service: 'program:shelflife:service:benin',
    shortServiceId: 'benin',
    geoId: 'country:ng:state:edo',
    aliases: ['edo', 'benin'],
    rdsMarketAlias: 'edo',
    state: 'edo',
    name: 'Edo',
    packPointId: 'country:ng:state:edo:sdp:shelf-life-benin-held',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:EDO-'),
    rdsIdPrefix: 'EDO',
    relatedTerritories: ['program:shelflife:service:delta'],
    freshsalesId: 30000004601,
    primaryResupplier: 'country:ng:state:lagos:sdp:mebs-facility-lagos'
  },
  {
    service: 'program:shelflife:service:ibadan',
    shortServiceId: 'ibadan',
    geoId: 'country:ng:state:oyo',
    aliases: ['oyo'],
    rdsMarketAlias: 'oyo',
    state: 'oyo',
    name: 'Oyo',
    packPointId: 'country:ng:state:oyo:sdp:blissland-pack-point',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:OYO-'),
    relatedTerritories: ['program:shelflife:service:kwara', 'program:shelflife:service:osun', 'program:shelflife:service:ekiti', 'program:shelflife:service:ondo'],
    rdsIdPrefix: 'OYO',
    freshsalesId: 30000001771,
    primaryResupplier: 'country:ng:state:lagos:sdp:mebs-facility-lagos'
  },
  {
    service: 'program:shelflife:service:kaduna',
    shortServiceId: 'kaduna',
    geoId: 'country:ng:state:kaduna',
    aliases: ['kaduna', 'kd'],
    rdsMarketAlias: 'kd',
    state: 'kaduna',
    name: 'Kaduna',
    packPointId: 'country:ng:state:kaduna:sdp:shelf-life-kaduna-held',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:KD-'),
    rdsIdPrefix: 'KD',
    freshsalesId: 30000004224,
    primaryResupplier: 'country:ng:state:lagos:sdp:mebs-facility-lagos'
  },
  {
    service: 'program:shelflife:service:nyanza',
    shortServiceId: 'nyanza',
    geoId: 'country:ke:state:nyanza',
    aliases: ['nyanza', 'nyz'],
    rdsMarketAlias: 'nyanza',
    state: 'nyanza',
    name: 'Nyanza',
    returnShipmentDestinationId: 'country:ke:state:nyanza:sdp:shelf-life-nyanza-held',
    packPointId: 'country:ke:state:nyanza:sdp:shelf-life-nyanza-held',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:NYZ-'),
    relatedTerritories: ['program:shelflife:service:kenya', 'program:shelflife:service:coastal', 'program:shelflife:service:south-rift', 'program:shelflife:service:north-rift', 'program:shelflife:service:antara', 'program:shelflife:service:penda', 'program:shelflife:service:central-province'],
    rdsIdPrefix: 'NYZ',
    freshsalesId: 30000001773
  },
  {
    service: 'program:shelflife:service:coastal',
    shortServiceId: 'coastal',
    geoId: 'country:ke:state:coastal',
    aliases: ['coastal', 'cstl', 'cst'],
    rdsMarketAlias: 'coastal',
    state: 'coastal',
    name: 'Coastal',
    returnShipmentDestinationId: 'country:ke:state:coastal:sdp:shelf-life-coastal-held',
    packPointId: 'country:ke:state:coastal:sdp:shelf-life-coastal-held',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:CSTL-'),
    relatedTerritories: ['program:shelflife:service:kenya', 'program:shelflife:service:nyanza', 'program:shelflife:service:south-rift', 'program:shelflife:service:north-rift', 'program:shelflife:service:antara', 'program:shelflife:service:penda', 'program:shelflife:service:central-province'],
    rdsIdPrefix: 'CSTL',
    freshsalesId: 30000004600
  },
  {
    service: 'program:shelflife:service:kwara',
    shortServiceId: 'kwara',
    geoId: 'country:ng:state:kwara',
    aliases: ['kwara', 'kw', 'KWA'],
    rdsMarketAlias: 'kwara',
    state: 'kwara',
    name: 'Kwara',
    packPointId: 'country:ng:state:oyo:sdp:blissland-pack-point',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:KW-'),
    relatedTerritories: ['program:shelflife:service:ibadan', 'program:shelflife:service:osun', 'program:shelflife:service:ekiti', 'program:shelflife:service:ondo'],
    rdsIdPrefix: 'KW',
    freshsalesId: 30000004602
  },
  {
    service: 'program:shelflife:service:kano',
    shortServiceId: 'kano',
    geoId: 'country:ng:state:kano',
    aliases: ['kano', 'kn', 'kan'],
    rdsMarketAlias: 'kan',
    state: 'kano',
    name: 'Kano',
    packPointId: 'country:ng:state:kano:sdp:najbel-pack-point',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:KN-'),
    rdsIdPrefix: 'KN',
    freshsalesId: 30000004534,
    primaryResupplier: 'country:ng:state:lagos:sdp:mebs-facility-lagos'
  },
  {
    service: 'program:shelflife:service:enugu',
    shortServiceId: 'enugu',
    geoId: 'country:ng:state:enugu',
    aliases: ['enugu', 'eg', 'ENU', 'enu'],
    rdsMarketAlias: 'enugu',
    state: 'enugu',
    name: 'Enugu',
    packPointId: 'country:ng:state:enugu:sdp:shelf-life-enugu-held',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:ENU-'),
    rdsIdPrefix: 'ENU',
    freshsalesId: 30000004605,
    primaryResupplier: 'country:ng:state:lagos:sdp:mebs-facility-lagos'
  },
  {
    service: 'program:shelflife:service:delta',
    shortServiceId: 'delta',
    geoId: 'country:ng:state:delta',
    aliases: ['delta', 'dl', 'DEL', 'del'],
    rdsMarketAlias: 'delta',
    state: 'delta',
    name: 'Delta',
    packPointId: 'country:ng:state:delta:sdp:shelf-life-delta-held',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:DEL-'),
    rdsIdPrefix: 'DEL',
    relatedTerritories: ['program:shelflife:service:benin'],
    freshsalesId: 30000001770,
    primaryResupplier: 'country:ng:state:lagos:sdp:mebs-facility-lagos'
  },
  {
    service: 'program:shelflife:service:south-rift',
    shortServiceId: 'south-rift',
    geoId: 'country:ke:state:south-rift',
    returnShipmentDestinationId: 'country:ke:state:south-rift:sdp:shelf-life-south-rift-held',
    packPointId: 'country:ke:state:south-rift:sdp:shelf-life-south-rift-held',
    aliases: ['south-rift', 'SR', 'sr', 'South Rift', 'south rift', 'SOUTH RIFT'],
    rdsMarketAlias: 'south-rift',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:SR-'),
    rdsIdPrefix: 'SR',
    name: 'South Rift',
    state: 'south-rift',
    freshsalesId: 30000001601,
    relatedTerritories: ['program:shelflife:service:kenya', 'program:shelflife:service:coastal', 'program:shelflife:service:nyanza', 'program:shelflife:service:north-rift', 'program:shelflife:service:antara', 'program:shelflife:service:penda', 'program:shelflife:service:central-province']
  },
  {
    service: 'program:shelflife:service:north-rift',
    shortServiceId: 'north-rift',
    geoId: 'country:ke:state:north-rift',
    returnShipmentDestinationId: 'country:ke:state:north-rift:sdp:shelf-life-north-rift-held',
    packPointId: 'country:ke:state:north-rift:sdp:shelf-life-north-rift-held',
    aliases: ['north-rift', 'NR', 'nr', 'North Rift', 'north rift', 'NORTH RIFT'],
    rdsMarketAlias: 'north-rift',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:NR-'),
    rdsIdPrefix: 'NR',
    name: 'North Rift',
    state: 'north-rift',
    freshsalesId: 30000001678,
    relatedTerritories: ['program:shelflife:service:kenya', 'program:shelflife:service:coastal', 'program:shelflife:service:south-rift', 'program:shelflife:service:nyanza', 'program:shelflife:service:antara', 'program:shelflife:service:penda', 'program:shelflife:service:central-province']
  },
  {
    service: 'program:shelflife:service:antara',
    shortServiceId: 'antara',
    geoId: 'country:ke:state:antara',
    aliases: ['antara', 'ant', 'Antara'],
    rdsMarketAlias: 'antara',
    state: 'antara',
    name: 'Antara',
    packPointId: 'country:ke:state:nairobi:sdp:shelf-life-nairobi-held',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:ANT-'),
    relatedTerritories: ['program:shelflife:service:kenya', 'program:shelflife:service:coastal', 'program:shelflife:service:nyanza', 'program:shelflife:service:south-rift', 'program:shelflife:service:north-rift', 'program:shelflife:service:penda', 'program:shelflife:service:central-province'],
    rdsIdPrefix: 'ANT'
  },
  {
    service: 'program:shelflife:service:penda',
    shortServiceId: 'penda',
    geoId: 'country:ke:state:penda',
    aliases: ['penda', 'pen', 'Penda'],
    rdsMarketAlias: 'penda',
    state: 'penda',
    name: 'Penda',
    packPointId: 'country:ke:state:penda:sdp:sl-penda-hq',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:PEN-'),
    relatedTerritories: ['program:shelflife:service:kenya', 'program:shelflife:service:coastal', 'program:shelflife:service:nyanza', 'program:shelflife:service:south-rift', 'program:shelflife:service:north-rift', 'program:shelflife:service:antara', 'program:shelflife:service:central-province'],
    rdsIdPrefix: 'PEN',
    freshsalesId: 30000004984
  },
  {
    service: 'program:shelflife:service:medbury',
    shortServiceId: 'medbury',
    geoId: 'country:ng:state:medbury',
    aliases: ['med', 'medbury', 'Medbury', 'MEDBURY', 'MED'],
    rdsMarketAlias: 'medbury',
    state: 'medbury',
    name: 'Medbury',
    packPointId: 'country:ng:state:lagos:sdp:mebs-facility-lagos',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:MED-'),
    relatedTerritories: ['program:shelflife:service:lagos', 'program:shelflife:service:abeokuta', 'program:shelflife:service:demo', 'program:shelflife:service:cedar'],
    rdsIdPrefix: 'MED',
    freshsalesId: 30000005385
  },
  {
    service: 'program:shelflife:service:cedar',
    shortServiceId: 'cedar',
    geoId: 'country:ng:state:cedar',
    aliases: ['cedar', 'cdr', 'Cedar', 'CDR', 'CEDAR'],
    rdsMarketAlias: 'cdr',
    state: 'cedar',
    name: 'cedar',
    packPointId: 'country:ng:state:lagos:sdp:mebs-facility-lagos',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:CDR-'),
    relatedTerritories: ['program:shelflife:service:lagos', 'program:shelflife:service:abeokuta', 'program:shelflife:service:demo', 'program:shelflife:service:medbury'],
    rdsIdPrefix: 'CDR',
    freshsalesId: 30000005074
  },
  {
    service: 'program:shelflife:service:demo',
    shortServiceId: 'demo',
    geoId: 'country:ng:state:demo',
    aliases: ['demo', 'dm', 'Demo', 'DM', 'DEMO'],
    rdsMarketAlias: 'demo',
    state: 'demo',
    name: 'demo',
    packPointId: 'country:ng:state:lagos:sdp:shelf-life-lagos-held',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:DM-'),
    relatedTerritories: ['program:shelflife:service:lagos', 'program:shelflife:service:abeokuta', 'program:shelflife:service:cedar', 'program:shelflife:service:medbury'],
    rdsIdPrefix: 'DM'
  },
  {
    service: 'program:shelflife:service:nasdma',
    shortServiceId: 'nasdma',
    geoId: 'country:ng:state:nasdma',
    aliases: ['nasarawa-dma', 'nasarawa-state-drugs-and-supply-management-agency-dc', 'nasdma', 'NASDMA'],
    rdsMarketAlias: 'nasdma',
    state: 'nasdma',
    name: 'NASDMA',
    packPointId: 'country:ng:state:fct:sdp:shelf-life-fct-held',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:NASDMA-'),
    relatedTerritories: ['program:shelflife:service:pharmaceuticals', 'program:shelflife:service:nasarawa'],
    rdsIdPrefix: 'NASDMA',
    freshsalesId: 30000005159
  },
  {
    service: 'program:shelflife:service:reliance',
    shortServiceId: 'reliance',
    geoId: 'country:ng:state:reliance',
    aliases: ['reliance', 'Reliance', 'RELIANCE', 'REL', 'rel'],
    rdsMarketAlias: 'reliance',
    state: 'reliance',
    name: 'Reliance',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:REL-'),
    rdsIdPrefix: 'REL',
    freshsalesId: 30000005410
  },
  {
    service: 'program:shelflife:service:rjolad',
    shortServiceId: 'rjolad',
    geoId: 'country:ng:state:rjolad',
    aliases: ['rjolad', 'RJolad', 'RJOLAD', 'RJO', 'rjo'],
    rdsMarketAlias: 'rjolad',
    state: 'rjolad',
    name: 'rjolad',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:RJO-'),
    rdsIdPrefix: 'RJO',
    freshsalesId: 30000005427
  },
  {
    service: 'program:shelflife:service:nhsp',
    shortServiceId: 'nhsp',
    geoId: 'country:ng:state:nhsp',
    aliases: ['NHSP', 'nhsp'],
    rdsMarketAlias: 'nhsp',
    state: 'nhsp',
    name: 'nhsp',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:NHSP-'),
    rdsIdPrefix: 'NHSP',
    freshsalesId: 30000005453
  },
  {
    service: 'program:shelflife:service:akwa-ibom',
    shortServiceId: 'akwa-ibom',
    geoId: 'country:ng:state:akwa-ibom',
    aliases: ['akwa-ibom', 'ak', 'AK', 'Akwa Ibom', 'akwa ibom', 'akwaibom', 'AKWAIBOM'],
    rdsMarketAlias: 'akwa-ibom',
    state: 'akwa-ibom',
    name: 'Akwa Ibom',
    packPointId: 'country:ng:state:rivers:sdp:shelf-life-ph-held',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:AK-'),
    relatedTerritories: ['program:shelflife:service:port-harcourt'],
    rdsIdPrefix: 'AK',
    freshsalesId: 30000005487,
    primaryResupplier: 'country:ng:state:lagos:sdp:mebs-facility-lagos'
  },
  {
    service: 'program:shelflife:service:osun',
    shortServiceId: 'osun',
    geoId: 'country:ng:state:osun',
    aliases: ['osun', 'osn', 'OSN', 'Osun'],
    rdsMarketAlias: 'osun',
    state: 'osun',
    name: 'Osun',
    packPointId: 'country:ng:state:oyo:sdp:blissland-pack-point',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:OSN-'),
    relatedTerritories: ['program:shelflife:service:kwara', 'program:shelflife:service:ondo', 'program:shelflife:service:ekiti', 'program:shelflife:service:ibadan'],
    rdsIdPrefix: 'OSN',
    freshsalesId: 30000005488,
    primaryResupplier: 'country:ng:state:lagos:sdp:mebs-facility-lagos'
  },
  {
    service: 'program:shelflife:service:ekiti',
    shortServiceId: 'ekiti',
    geoId: 'country:ng:state:ekiti',
    aliases: ['ekiti', 'ekt', 'EKT', 'Ekiti'],
    rdsMarketAlias: 'ekiti',
    state: 'ekiti',
    name: 'Ekiti',
    packPointId: 'country:ng:state:oyo:sdp:blissland-pack-point',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:EKT-'),
    relatedTerritories: ['program:shelflife:service:kwara', 'program:shelflife:service:osun', 'program:shelflife:service:ondo', 'program:shelflife:service:ibadan'],
    rdsIdPrefix: 'EKT',
    freshsalesId: 30000005489,
    primaryResupplier: 'country:ng:state:lagos:sdp:mebs-facility-lagos'
  },
  {
    service: 'program:shelflife:service:ondo',
    shortServiceId: 'ondo',
    geoId: 'country:ng:state:ondo',
    aliases: ['ondo', 'ond', 'OND', 'Ondo'],
    rdsMarketAlias: 'ondo',
    state: 'ondo',
    name: 'Ondo',
    packPointId: 'country:ng:state:oyo:sdp:blissland-pack-point',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:OND-'),
    relatedTerritories: ['program:shelflife:service:kwara', 'program:shelflife:service:osun', 'program:shelflife:service:ekiti', 'program:shelflife:service:ibadan'],
    rdsIdPrefix: 'OND',
    freshsalesId: 30000005490,
    primaryResupplier: 'country:ng:state:lagos:sdp:mebs-facility-lagos'
  },
  {
    service: 'program:shelflife:service:central-province',
    shortServiceId: 'central-province',
    geoId: 'country:ke:state:central-province',
    returnShipmentDestinationId: 'country:ke:state:central-province:sdp:shelf-life-central-province-held',
    packPointId: 'country:ke:state:central-province:sdp:shelf-life-central-province-held',
    aliases: ['central-province', 'CP', 'cp', 'Central Province', 'central province'],
    rdsMarketAlias: 'central-province',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:CP-'),
    rdsIdPrefix: 'CP',
    name: 'Central Province',
    state: 'central-province',
    freshsalesId: 30000005491,
    relatedTerritories: ['program:shelflife:service:kenya', 'program:shelflife:service:coastal', 'program:shelflife:service:south-rift', 'program:shelflife:service:nyanza', 'program:shelflife:service:antara', 'program:shelflife:service:penda', 'program:shelflife:service:north-rift']
  },
  {
    service: 'program:shelflife:service:katsina',
    shortServiceId: 'katsina',
    geoId: 'country:ng:state:katsina',
    returnShipmentDestinationId: 'country:ng:state:katsina:sdp:katsina-state-medicine-supply-agency',
    packPointId: 'country:ng:state:katsina:sdp:katsina-state-medicine-supply-agency',
    aliases: ['katsina', 'KT', 'kt', 'Katsina', 'KATSINA'],
    rdsMarketAlias: 'katsina',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:KT-'),
    rdsIdPrefix: 'KT',
    name: 'Katsina',
    state: 'katsina',
    freshsalesId: 30000005515,
    primaryResupplier: 'country:ng:state:lagos:sdp:mebs-facility-lagos'
  },
  {
    service: 'program:shelflife:service:mncnh',
    shortServiceId: 'mncnh',
    geoId: 'country:ng:state:mncnh',
    aliases: ['mncnh', 'MNCNH'],
    rdsMarketAlias: 'mncnh',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:MNCNH-'),
    rdsIdPrefix: 'MNCNH',
    name: 'MNCNH',
    state: 'mncnh',
    freshsalesId: 30000005613,
    primaryResupplier: 'country:ng:state:lagos:sdp:mebs-facility-lagos'
  },
  {
    service: 'program:shelflife:service:equityafia',
    shortServiceId: 'equityafia',
    geoId: 'country:ke:state:equityafia',
    aliases: ['EQAF', 'eqaf', 'EquityAfia', 'EQUITYAFIA', 'equityafia'],
    rdsMarketAlias: 'equityafia',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:EQAF-'),
    rdsIdPrefix: 'EQAF',
    name: 'EquityAfia',
    state: 'equityafia',
    freshsalesId: 30000005647,
    primaryResupplier: 'country:ke:state:nairobi:sdp:shelf-life-nairobi-held',
    packPointId: 'country:ke:state:nairobi:sdp:shelf-life-nairobi-held'
  },
  {
    service: 'program:shelflife:service:bauchi',
    shortServiceId: 'bauchi',
    geoId: 'country:ng:state:bauchi',
    aliases: ['bauchi', 'bch', 'BCH', 'BAUCHI'],
    rdsMarketAlias: 'BCH',
    state: 'bauchi',
    name: 'Bauchi',
    packPointId: 'country:ng:state:kano:sdp:najbel-pack-point',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:BCH-'),
    rdsIdPrefix: 'BCH',
    freshsalesId: 30000005720,
    primaryResupplier: 'country:ng:state:lagos:sdp:mebs-facility-lagos'
  },
  {
    service: 'program:shelflife:service:karen',
    shortServiceId: 'karen',
    geoId: 'country:ke:state:karen',
    aliases: ['KAREN', 'karen', 'KRN', 'krn', 'Karen'],
    rdsMarketAlias: 'karen',
    isProductIDMemberFunction: (productId) => productId.startsWith('product:KRN-'),
    rdsIdPrefix: 'KRN',
    name: 'Karen',
    state: 'karen',
    freshsalesId: 30000005768,
    primaryResupplier: 'country:ke:state:nairobi:sdp:shelf-life-nairobi-held',
    packPointId: 'country:ke:state:nairobi:sdp:shelf-life-nairobi-held'
  }
]

const COUNTRIES_MAP = [{
  name: 'Nigeria',
  geoId: 'country:ng'
}, {
  name: 'Kenya',
  geoId: 'country:ke'
}]

exports.territoriesByCountry = (countryGeoId) =>
  TERRITORIES_MAP.filter(t => t.geoId.startsWith(countryGeoId))

exports.territoryByAlias = (alias) =>
  TERRITORIES_MAP.find(t =>
    (t.aliases || []).some(a =>
      a === (alias || '').toLowerCase()
    )
  )

exports.territoryByName = name => {
  if (!name) { return }
  return TERRITORIES_MAP
    .filter(t => t.name)
    .find(t => t.name.toLowerCase() === name.toLowerCase())
}

exports.territoryByFreshsalesId = (id, stateName) => {
  const territories = TERRITORIES_MAP
    .filter(t => t.freshsalesId === id)

  if (territories.length === 1) {
    return territories[0]
  }

  // Otherwise it is a territory that contains more than one state
  // If we don't know the state name we cannot choose the correct one
  if (!stateName) {
    return
  }
  // The state might be coming directly from Freshsales, where it is
  // a free text field, it can have extra spaces and a weird combination
  // of upper and lower characters
  return territories.find(t =>
    t.name.toLowerCase() === stateName.trim().toLowerCase()
  )
}

exports.locationsTerritoryLookup = (market) => {
  if (typeof market === 'object') {
    market = market.state
  }
  const byAlias = TERRITORIES_MAP
    .reduce((acc, territory) => {
      territory.aliases.forEach(alias => {
        if (acc[alias]) throw new Error(`duplicate alias found! ${alias}`)
        acc[alias] = territory
      })
      return acc
    }, {})

  if (!market) {
    throw new Error(`Cannot lookup territory without market code or state column`)
  }

  const territory = byAlias[market.toLowerCase()]

  if (!territory) {
    return
  }

  return territory
}

exports.getServiceForLocationId = getServiceForLocationId
function getServiceForLocationId (locationId) {
  const geoId = locationId.includes(':sdp') ? locationId.split(':sdp:')[0] : locationId
  return TERRITORIES_MAP.find(territory => territory.geoId === geoId)
}

exports.getCitiesByCountryCode = getCitiesByCountryCode
function getCitiesByCountryCode (countryCode) {
  return TERRITORIES_MAP.filter(territory => (territory.geoId.includes(countryCode) && territory.name))
}

exports.countryById = id =>
  COUNTRIES_MAP.find(c => c.geoId === id)

exports.getCountries = () => COUNTRIES_MAP

// TODO so, obviously, this needs to be burned as soon as we normalise territories
// https://github.com/fielded/van-orga/issues/4568
exports.getServiceForProduct = (product) => {
  // poorly planned and implemented satellite nasarawa has sku ids in the 3000 range
  // but are marked "FCT"

  let market = (product.additionalData || {}).market
  const sku = product._id.replace('product:', '').replace(',', '')
  if (sku.match(/3[0-9]{3}/) || sku.startsWith('NAS-')) {
    market = 'NAS'
  }

  const territory = TERRITORIES_MAP.find(territory => territory.aliases.includes(market && market.toLowerCase()))

  if (!territory) throw new Error(`could not find market for product id ${product._id} market ${market}`)

  return territory.service
}

exports.isProductIDInTerritory = isProductIDInTerritory
function isProductIDInTerritory (productId, locationId, includeRelatedTerritories = true) {
  if (!productId || !locationId) return false

  const territoryInfo = getServiceForLocationId(locationId) || {}
  const lookupFunction = territoryInfo.isProductIDMemberFunction
  if (!lookupFunction) return false

  const inTerritory = lookupFunction(productId)
  if (inTerritory) return true

  if (includeRelatedTerritories) {
    if (!territoryInfo.relatedTerritories) return false

    // if there's a related territory, also check if it's a relevant product
    // in that territory
    return territoryInfo.relatedTerritories.some(relatedTerritoryId => {
      const relatedTerritory = TERRITORIES_MAP.find(territory => territory.service === relatedTerritoryId)
      return relatedTerritory.isProductIDMemberFunction && relatedTerritory.isProductIDMemberFunction(productId)
    })
  } else {
    return false
  }
}

exports.getTerritoryForProduct = getTerritoryForProduct
function getTerritoryForProduct (product) {
  if (!product) return

  const productId = product.includes('product:') ? product : `product:${product}`

  const territory = TERRITORIES_MAP.find(territory =>
    territory.isProductIDMemberFunction && territory.isProductIDMemberFunction(productId)
  )

  return territory
}

// inclusive start, exclusive end
function codeRange (productId, start, end) {
  const [, code] = productId.split('product:')
  const number = Number(code)
  if (isNaN(number)) return false
  return number < end && code >= start
}

function getReturnShipmentDestinationByState (state) {
  const territory = TERRITORIES_MAP.find(territory => territory.state === state)
  if (!territory || !(territory && territory.returnShipmentDestinationId)) return null
  return territory.returnShipmentDestinationId
}
exports.getReturnShipmentDestinationByState = getReturnShipmentDestinationByState

exports.TERRITORIES_MAP = TERRITORIES_MAP
