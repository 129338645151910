const PGAdapter = require('./../../common/pg-adapter')
const {
  getPositionalArgs,
  matchAllValuesILikePreparer,
  arrayOrStringPreparer
} = require('./../../tools/sql-tools')
const { getPendingDeliveriesQuery } = require('./queries')

const DATA_SHIPMENT_TABLE_NAME = 'avocado.data_shipment'
const DATA_SHIPMENT_COLUMNS = [
  'id',
  'shipment_id',
  'status',
  'created_at',
  'updated_at',
  'created_by',
  'updated_by',
  'notes',
  'destination_id',
  'origin_id',
  'planning_type',
  'order_id',
  'route_id',
  'funder_id',
  'program_id',
  'vendor_id'
]

const DATA_SHIPMENT_VIRTUAL_COLUMNS = [
  'split_part(destination_id, \':\', 4)',
  'updated_at_gte',
  'updated_at_lte'
]

const DATA_SHIPMENTLINE_TABLE_NAME = 'avocado.data_shipmentline'

class DataShipmentAdapter extends PGAdapter {
  static get lastMileDeliveryFilter () {
    return {
      origin_id: 'zone:%:name:%', // warehouse
      destination_id: 'zone:%:state:%:lga:%:sdp:%'// Health facility
    }
  }

  constructor (pgConnection, username, logger) {
    const opts = {
      getRelated: {
        'lines': {
          relatedTable: DATA_SHIPMENTLINE_TABLE_NAME,
          fk: 'shipment_id'
        }
      },
      'virtualColumns': DATA_SHIPMENT_VIRTUAL_COLUMNS
    }

    const columnPreparers = {
      origin_id: matchAllValuesILikePreparer,
      destination_id: matchAllValuesILikePreparer,
      shipment_id: arrayOrStringPreparer,
      funder_id: arrayOrStringPreparer,
      route_id: arrayOrStringPreparer,
      program_id: arrayOrStringPreparer,
      status: arrayOrStringPreparer,
      created_at: (key, value) => [{statement: `"${key}">=%INDEX%`, value}],
      updated_at_gte: (key, value) => [{statement: `"updated_at">=%INDEX%`, value: value}],
      updated_at_lte: (key, value) => [{statement: `"updated_at"<=%INDEX%`, value: value}],
      // Calculated column: default preparer without adding double quotes to the key
      'split_part(destination_id, \':\', 4)': (key, value) => [{statement: `${key}=%INDEX%`, value}]
    }

    super(
      pgConnection,
      DATA_SHIPMENT_TABLE_NAME,
      username,
      DATA_SHIPMENT_COLUMNS,
      undefined,
      columnPreparers,
      logger,
      opts
    )
  }

  async getPendingDeliveries ({locationId, weeks}) {
    const query = getPendingDeliveriesQuery(weeks)
    const { rows } = await this.pgConnection.query(query, [locationId])
    return rows
  }

  async getShipments ({
    originId,
    destinationId,
    status = [],
    weeks = 0 // get shipments in the past n weeks
  }) {
    if (!originId && !destinationId) {
      throw new Error(`at least one of 'originId' or 'destinationId' is required`)
    }
    const params = []
    let query = `
      SELECT
        s.id,
        s.shipment_id,
        s.status,
        s.origin_id,
        s.destination_id,
        s.planning_type,
        s.order_id,
        concat('product:', sl.sku) as sku,
        sl.amount as quantity,
        s.created_at,
        s.updated_at
      FROM ${DATA_SHIPMENT_TABLE_NAME} s
      LEFT JOIN ${DATA_SHIPMENTLINE_TABLE_NAME} sl on s.id = sl.shipment_id
      WHERE
    `
    if (originId) {
      params.push(originId)
      query = query.concat(` origin_id = $${params.length}`)
    }
    if (destinationId && params.length >= 1) {
      params.push(destinationId)
      query = query.concat(` AND destination_id = $${params.length}`)
    } else if (destinationId && params.length === 0) {
      params.push(destinationId)
      query = query.concat(` destination_id = $${params.length}`)
    }
    if (status.length > 0) {
      const start = params.length + 1
      const args = getPositionalArgs(status, start)
      params.push(...status)
      query = query.concat(` AND status IN (${args})`)
    }
    if (typeof (weeks) === 'number') {
      query = query.concat(` AND s.created_at + '${weeks} week'::INTERVAL >= current_date order by s.created_at`)
    }
    const { rows } = await this.pgConnection.query(query, params)
    return rows
  }

  async getLastMileDeliveriesFilterValues (selectedFilter) {
    const tableName = 'data_shipment'
    const filteringTableColumns = [
      'route_id',
      'program_id',
      'funder_id',
      // TODO, we don't sync this prop yet to RDS
      // 'delivery_date',
      'vendor_id',
      'created_at',
      'origin_id',
      'destination_id',
      'status'
    ]

    const filteringCalculatedColumns = [
      { name: 'state', expression: 'split_part(destination_id, \':\', 4)' }
    ]

    const preFilter = {...DataShipmentAdapter.lastMileDeliveryFilter, ...selectedFilter}
    return this.getColumnsUniqueValues(
      filteringTableColumns,
      filteringCalculatedColumns,
      preFilter,
      tableName,
      this.columnPreparers
    )
  }

  async getShipmentProgramId (shipmentId) {
    if (!shipmentId) {
      throw new Error('shipmentId required to get program id')
    }

    const { rows } = await this.pgConnection.query(`
      select
        coalesce(o.program_id, s.program_id) as id,
        replace(coalesce(o.program_id, s.program_id), 'program:', '') as name
      from avocado.data_shipment s
      inner join avocado.data_order o on s.order_id = o.order_id and s.destination_id = o.destination_id
      where s.shipment_id = $1
    `, [shipmentId])
    return rows[0]
  }

  async getVolume (shipmentIds) {
    const { rows } = await this.pgConnection.query(
      `select 
        s.shipment_id as shipment_id,
        round(sum(sl.amount * (id.doc->>'unitVolume')::double precision)::numeric, 2) as total_volume
      from avocado.data_shipment s
      inner join avocado.data_shipmentline sl on sl.shipment_id = s.id
      left join couchdb.db_integrated_data id on id.doc->>'_id' = concat('product:', sl.sku)
      where s.shipment_id = any($1) 
      group by s.shipment_id`,
      [shipmentIds]
    )
    if (!rows) {
      return []
    }
    return rows
  }
}

module.exports = DataShipmentAdapter
