import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import flowRight from 'lodash/flowRight'

import { BackButton, Loading } from '@fielded/shared-ui'
import withConfig from '../../../van-shared/hoc/withConfig'
import withShipment from '../common/WithShipmentWrapper'
import withMasterData from '../common/withMasterData'
import { withApi } from '../../../common/ApiProvider'

import getTemplate from './get-template'
import { shipmentForPrinting } from './selectors'
import { getShipmentWithSurveys } from './utils'
import { selectMasterData } from '../../../root/reducers/shipments/master-data'

const PrintContainer = ({
  api,
  shipment,
  history,
  config,
  match
}) => {
  const masterData = useSelector(selectMasterData)
  const [shipmentWithSurveys, setShipmentWithSurveys] = useState(null)
  const [allProductsbyId, setAllProductsbyId] = useState(null)

  const originalProductsById = get(masterData, 'products.byId', {})

  const initialize = async () => {
    const formattedShipment = await shipmentForPrinting({masterData}, shipment, originalProductsById, api)
    const surveyDocs = await api.survey.findAllForShipment(formattedShipment.id)

    const shipmentWithSurveys = getShipmentWithSurveys(formattedShipment, surveyDocs)
    const { allProductsbyId } = formattedShipment

    setAllProductsbyId(allProductsbyId)
    setShipmentWithSurveys(shipmentWithSurveys)
  }

  useEffect(() => {
    initialize()
  }, [])

  if (!shipmentWithSurveys) {
    return <Loading />
  }

  const showAllStatements = !!match.params.allStatements
  const printTemplate = get(history, 'location.state.note')

  const PrintComponent = getTemplate(shipmentWithSurveys, config, printTemplate)

  return (
    <div className='print page'>
      <header className='header--back'>
        <BackButton history={history}>go back</BackButton>
      </header>
      <PrintComponent
        shipment={shipmentWithSurveys}
        productsById={allProductsbyId}
        config={config}
        showAllStatements={showAllStatements}
      />
    </div>
  )
}

const withHOCs = flowRight(
  withApi,
  withConfig,
  withMasterData,
  withShipment
)

export default withHOCs(PrintContainer)
