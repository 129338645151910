const PGAdapter = require('./../../common/pg-adapter')
const { arrayOrStringPreparer } = require('../../tools/sql-tools')

const ROUTE_TABLE_NAME = 'avocado.data_route'
const ROUTE_COLUMNS = [
  'id',
  'couchdb_id',
  'couchdb_service_id',
  'name',
  'delivery_day',
  'delivery_frequency',
  'first_delivery_date',
  'count_day',
  'count_frequency',
  'first_count_date',
  'created_by',
  'created_at',
  'updated_by',
  'updated_at',
  'delivery_cycle_weeks',
  'count_cycle_weeks',
  'status',
  'start_date',
  'end_date',
  'approval_date'
]

class RoutePGDataAdapter extends PGAdapter {
  constructor (pgConnection, username, logger) {
    const virtualColumns = ['shipments']
    const getRelated = {
      shipments: {
        relatedTable: 'avocado.data_shipment',
        pk: 'couchdb_id',
        fk: 'route_id'
      }
    }

    const columnPreparers = {
      couchdb_id: arrayOrStringPreparer,
      start_date: (key, value) => [{statement: `"${key}">=%INDEX%`, value}],
      end_date: (key, value) => [{statement: `"${key}"<=%INDEX%`, value}],
      status: arrayOrStringPreparer
    }

    super(
      pgConnection,
      ROUTE_TABLE_NAME,
      username,
      ROUTE_COLUMNS,
      undefined,
      columnPreparers,
      logger,
      { getRelated, virtualColumns }
    )
  }
}

module.exports = RoutePGDataAdapter
