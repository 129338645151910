/** Validate supported allDocs options
 *
 * The options are mimicking the PouchDB.allDocs options.
 * A lot of the options have not been implemented so far.
 */
function validateAllDocsOptions (options = {}) {
  // {
  //   include_docs = false,
  //   conflicts = null, // Not implemented
  //   attachments = null, // Not implemented
  //   binary = null, // Not implemented
  //   startkey = null, // Not implemented
  //   endkey = null, // Not implemented
  //   'inclusive_end' = true, // `false` Not implemented
  //   limit = null, // Not implemented
  //   skip = null, // Not implemented
  //   key = null, // Not implemented
  //   keys = null
  // }
  const optsWithDefaults = {
    'inclusive_end': true,
    ...options
  }

  if (optsWithDefaults.conflicts != null) {
    throw new Error('conflicts option not supported')
  }
  if (optsWithDefaults.attachments != null) {
    throw new Error('attachments option not supported')
  }
  if (optsWithDefaults.binary != null) {
    throw new Error('binary option not supported')
  }
  if (optsWithDefaults.startkey != null) {
    throw new Error('startkey option not supported')
  }
  if (optsWithDefaults.endkey != null) {
    throw new Error('endkey option not supported')
  }
  if (optsWithDefaults['inclusive_end'] !== true) {
    throw new Error('inclusive_end option `false` not supported')
  }
  if (optsWithDefaults.limit != null) {
    throw new Error('binary option not supported')
  }
  if (optsWithDefaults.skip != null) {
    throw new Error('skip option not supported')
  }
  if (optsWithDefaults.key != null) {
    throw new Error('key option not supported')
  }
  return optsWithDefaults
}

module.exports = {
  validateAllDocsOptions
}
