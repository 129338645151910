const createGraph = require('../../../relationship-graph')
const { listAll } = require('./list-all')

exports.listChildren = async (state, id, {
  date,
  deep,
  includeSelf,
  filters,
  includeInactive = false,
  allLocations = null,
  graph = null
} = {}) => {
  const locationId = id || state.user.location.id
  if (!graph) {
    if (!allLocations) {
      allLocations = await listAll(state, {date, includeInactive})
    }
    graph = createGraph(allLocations)
  }
  // Check if we have a location with the id
  const self = await graph.getLocation(locationId)

  if (self) {
    let suppliees
    if (deep) {
      suppliees = await graph.getDescendantSuppliees(self._id, filters)
    } else {
      suppliees = await graph.getSuppliees(self._id, filters)
    }
    return includeSelf ? [self, ...suppliees] : suppliees
  } else {
    // No location found, treat the id as a geolocation id
    const locationsInArea = await graph.getLocationsInGeoLocation(locationId, filters)
    return locationsInArea
  }
}
