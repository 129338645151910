export const USING_FLAGS = 'usingFlags'

export const setIsUsingFlagsStatus = (usingFlags) => {
  window.localStorage.setItem(USING_FLAGS, JSON.stringify({ usingFlags }))
}

export const getIsUsingFlagsStatus = () => {
  const data = JSON.parse(window.localStorage.getItem(USING_FLAGS))
  return data ? data[USING_FLAGS] : !!data
}
