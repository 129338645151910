const { validateAllDocsOptions } = require('./validation')

class CouchDbPgDatabase {
  constructor (pgPool, dbName) {
    this.pgPool = pgPool
    this.dbName = dbName
  }

  async allDocs (options = {}) {
    options = validateAllDocsOptions(options)

    const keys = options.keys
    const includeDocs = options['include_docs']

    const tableName = `couchdb.db_${this.dbName.replace(/-/g, '_')}`

    let sparseRows
    if (includeDocs) {
      const queryResult = await this.pgPool.query(`
        SELECT id, rev, doc
        FROM ${tableName}
        WHERE id = ANY($1)
      `, [keys])
      sparseRows = queryResult.rows
    } else {
      const queryResult = await this.pgPool.query(`
        SELECT id, rev
        FROM ${tableName}
        WHERE id = ANY($1)
      `, [keys])
      sparseRows = queryResult.rows
    }

    let rows
    if (options.sparse) {
      rows = new Array(sparseRows.length)
      for (const [i, respRow] of sparseRows.entries()) {
        const row = {
          id: respRow.id,
          key: respRow.id,
          values: {
            rev: respRow.rev
          }
        }
        if (includeDocs) {
          row.doc = respRow.doc
        }
        rows[i] = row
      }
    } else {
      // The postgres query will only return rows for IDs that exist,
      // but in the couch-like response we want to include a not_found entry
      // for missing rows.
      rows = new Array(keys.length)
      let j = 0
      for (let i = 0; i < keys.length; ++i) {
        const reqKey = keys[i]
        const respRow = sparseRows[j]

        if (respRow && respRow.id === reqKey) {
          const row = {
            id: respRow.id,
            key: respRow.id,
            values: {
              rev: respRow.rev
            }
          }
          if (includeDocs) {
            row.doc = respRow.doc
          }
          ++j
          rows[i] = row
        } else {
          rows[i] = { key: reqKey, error: 'not_found' }
        }
      }
    }

    return {
      'total_rows': rows.length,
      rows,
      offset: 0
    }
  }
}

module.exports = {
  CouchDbPgDatabase
}
