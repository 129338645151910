module.exports = { ledgerBalanceToReport }

const cloneDeep = require('lodash/cloneDeep')
const { REPORT_BALANCE_FIELD_SHORT } = require('./utils/constants')
const { VERSIONS, DEFAULT_VERSION } = require('../utils/versions')
const toStockCountId = require('./to-stock-count-id')
const docToStockCountRecord = require('./doc-to-stock-count-record')
const getPeriodFromProgram = require('../report/tools/get-period-from-program')
const shouldTrackBatches = require('./should-track-batches')

function copyOpenings (stockPointer) {
  Object.keys(stockPointer).forEach(productId => {
    const fields = stockPointer[productId].fields || {}
    if (fields['field:partner-balance']) {
      fields['field:opening-partner-balance'] = cloneDeep(fields['field:partner-balance'])
    }

    if (fields['field:standard-physical-count']) {
      fields['field:standard-opening-balance'] = cloneDeep(fields['field:standard-physical-count'])
    }
  })
}

function ledgerBalanceToReport (params) {
  const { ledger, location, service, date, user, asDocument = false, fields = [], calculateFields = false } = params
  const ledgerFields = fields.filter(f => f.ledger === true)
  const reportingPeriod = getPeriodFromProgram(service.program, date, true)

  const _id = toStockCountId({ location: location.id, service, reportingPeriod: reportingPeriod.id })
  const version = VERSIONS[service.id]
    ? VERSIONS[service.id].current
    : DEFAULT_VERSION

  const stock = getStockReportStock(ledger, location, service, ledgerFields)
  const timestamp = new Date().toISOString()
  const report = {
    _id,
    version,
    stock,
    type: 'stockCount',
    serviceId: service.id,
    createdAt: timestamp,
    createdBy: user.name,
    updatedAt: timestamp,
    updatedBy: user.name,
    submittedAt: timestamp
  }

  let docParams
  if (calculateFields) {
    // Copy partner-balance to opening-partner-balance so the other fields are calculated correctly
    copyOpenings(report.stock)
    docParams = { fields, fieldOpts: { tracksPartnerBalances: location.tracksPartnerBalances } }
  }

  return asDocument
    ? report
    : docToStockCountRecord(report, service, docParams)
}

function getStockReportStock (ledger, location, service, fields) {
  // These are already the relevant products for our location:service.
  const areBatchesTracked = shouldTrackBatches({
    service,
    location
  })
  return Object.keys(ledger).reduce((memo, productId) => {
    const { batches, total, commits, skipCount } = ledger[productId]
    if (areBatchesTracked) {
      memo[productId] = { batches: {} }
      for (let batchId in batches) {
        const batchTotal = batches[batchId]
        memo[productId].batches[batchId] = {
          fields: { [REPORT_BALANCE_FIELD_SHORT]: { amount: batchTotal } }
        }
      }
    } else {
      memo[productId] = {
        fields: {
          [REPORT_BALANCE_FIELD_SHORT]: { amount: total }
        }
      }

      fields.forEach(field => {
        const id = field.id
        const amount = ledger[productId][id]
        memo[productId].fields[id] = { amount }
      })
    }
    if (commits) {
      memo[productId].commits = cloneDeep(commits)
    }

    if (skipCount) {
      memo[productId].skipCount = true
    }

    return memo
  }, {})
}
