const { toDraft } = require('../../tools')
const { createV1Id, createV2Id } = require('../tools/ids')
const getDraft = require('./get-draft')

module.exports = async (state, {
  locationId,
  service,
  period,
  entityOptions = null // {addProducts: false, addFields: false}
}) => {
  const draftId1 = toDraft(createV1Id({locationId, service, period}))
  const draft1 = await getDraft(state, {reportId: draftId1, service, period, entityOptions})
  if (draft1) {
    return draft1
  }

  const draftId2 = toDraft(createV2Id({locationId, service, period}))
  const draft2 = await getDraft(state, {reportId: draftId2, service, period, entityOptions})
  if (draft2) {
    return draft2
  }
}
