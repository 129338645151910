const { bulkImport } = require('./api/bulk-import')
const get = require('./api/get')
const getDraft = require('./api/get-draft')
const createDraft = require('./api/create-draft')
const findDraft = require('./api/find-draft')
const save = require('./api/save')
const createEmptyFullCount = require('./api/create-empty-full-count')
const createAutomaticFullCount = require('./api/create-automatic-full-count')
const sellOffSLBalance = require('./api/sell-off-sl-balance')
const saveDraft = require('./api/save-draft')
const submit = require('./api/submit')
const submitDraft = require('./api/submit-draft')
const submitPartial = require('./api/submit-partial')
const transfer = require('./api/transfer')
const prepareDraft = require('./api/prepare-draft')
const remove = require('./api/remove')
const removeDraft = require('./api/remove-draft')
const find = require('./api/find')
const findForLocation = require('./api/find-for-location')
const findWithAdjustments = require('./api/find-with-adjustments')
const getOverview = require('./api/get-overview')
const getOverviews = require('./api/get-overviews')
const { getPeriod, getPeriodDefinition, getNextPeriod, getPreviousPeriod, getPeriodFromId } = require('./api/get-periods')
const isOutdatedDraft = require('./api/is-outdated-draft')
const listForLMD = require('./api/list-for-lmd')
const addProductsToDraft = require('./api/add-products-to-draft')
const { EntityApi } = require('../common')
const { wrapEntityApi } = require('../utils/wrap-api')
const ReportPouchAdapter = require('./data-access/report-pouch-adapter')

const rawMethods = {
  bulkImport,
  get,
  remove,
  save,
  submit,
  transfer,
  submitPartial,
  find,
  findForLocation,
  findWithAdjustments,
  listForLMD,
  createEmptyFullCount,
  createAutomaticFullCount,
  sellOffSLBalance,
  draft: {
    create: createDraft,
    get: getDraft,
    find: findDraft,
    prepare: prepareDraft,
    remove: removeDraft,
    save: saveDraft,
    submit: submitDraft,
    isOutdated: isOutdatedDraft,
    addProducts: addProductsToDraft
  },
  period: {
    get: getPeriod,
    getDefinition: getPeriodDefinition,
    getNext: getNextPeriod,
    getPrevious: getPreviousPeriod,
    fromId: getPeriodFromId
  },
  overview: {
    get: getOverview,
    getMany: getOverviews
  }
}

class ReportApi extends EntityApi {
  constructor (state, restAdapter) {
    const { user, db } = state
    const adapter = new ReportPouchAdapter(user, db)
    super(adapter)

    // TODO: remove this when all raw methods have been ported
    const apiMethods = wrapEntityApi(rawMethods, state)
    Object.assign(this, apiMethods)
  }
}

module.exports = rawMethods
module.exports.ReportApi = ReportApi
