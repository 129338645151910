const { ProxifiedEntityApi, PGAdapter } = require('../common')

const STOP_MOVING_HISTORY_TABLE_NAME = 'avocado.data_route_stop_moving_history'
const STOP_MOVING_HISTORY_COLUMNS = [
  'id',
  'location_id',
  'route_from',
  'route_to',
  'route_from_status',
  'created_at',
  'updated_at',
  'created_by',
  'updated_by'
]

class StopMovingHistoryAdapter extends PGAdapter {
  constructor (pgConnection, username, logger) {
    super(
      pgConnection,
      STOP_MOVING_HISTORY_TABLE_NAME,
      username,
      STOP_MOVING_HISTORY_COLUMNS,
      undefined, // idColumn
      {}, // columnPreparers
      logger,
      undefined // opts
    )
  }
}

class StopMovingHistory extends ProxifiedEntityApi {
  constructor (state, agaveAdapter, logger, pgConnection) {
    const { user = {} } = state
    const username = user.name

    const adapter = new StopMovingHistoryAdapter(pgConnection, username, logger)

    super(
      'route/stop-moving-history',
      ['create', 'list'],
      !pgConnection,
      adapter,
      agaveAdapter
    )

    this.adapter = adapter
  }
}

module.exports = { StopMovingHistory }
