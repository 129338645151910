const { validateAllDocsOptions } = require('./validation')

class CouchDbRestDatabase {
  constructor (agave, dbName) {
    this.agave = agave
    this.dbName = dbName
  }

  async allDocs (options = {}) {
    options = validateAllDocsOptions(options)

    const includeDocs = options['include_docs']
    const keys = options['keys']
    const sparse = options['sparse']

    const query = [
      `include_docs=${includeDocs.toString()}`
    ]
    if (sparse) {
      query.push('sparse=true')
    }
    const url = `/couchdb-cache/db/${this.dbName}/_all_docs?${query.join('&')}`

    if (keys != null) {
      const rows = []
      const batchSize = 1500
      for (let i = 0; i < keys.length; i += batchSize) {
        const keysBatch = keys.slice(i, i + batchSize)
        const result = await this.agave.post(url, { keys: keysBatch })
        rows.push(...result.rows)
      }
      return {
        'total_results': rows.length,
        'offset': 0,
        'rows': rows
      }
    }

    return {
      'total_results': 0,
      'offset': 0,
      'rows': []
    }
  }
}

module.exports = {
  CouchDbRestDatabase
}
