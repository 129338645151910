
const getPendingDeliveriesQuery = (weeks) => {
  let weekFilter = ''
  if (typeof (weeks) === 'number') {
    weekFilter = `AND s.created_at + '${weeks} week'::INTERVAL >= current_date`
  }

  return `
    with pending_shipments as (
      select
        s.id,
        l1.fsid as destination_id,
        s.origin_id,
        s.status,
        s.shipment_id,
        s.created_at,
        s.updated_at,
        l1.service_start_date,
        l1.market_id as destination_market_id,
        l2.market_id as origin_market_id,
        l1.market_id != l2.market_id as cross_territory_shipment
      from avocado.data_location l1
      left join avocado.data_shipment s on s.destination_id = l1.fsid
      left join avocado.data_location l2 on l2.fsid = s.origin_id
      where s.status != 'received'
        AND l1.fsid = $1
        ${weekFilter}
    ),
    shipment_amount as (
      select
        ps.destination_id,
        ps.shipment_id,
        sum(sp.sellprice * sl.amount) as amount
      from pending_shipments ps
      left join avocado.data_shipmentline sl on ps.id = sl.shipment_id
      left join avocado.data_productsku sk on sk.old_sku_id = sl.sku
      left join lateral (
        -- Translate SKU between source and tarket market
        select ps2.old_sku_id as sku
        from avocado.data_productsku ps1
        inner join avocado.data_productsku ps2 on ps2.market_id = ps.destination_market_id
                                              and ps2.presentation_id = ps1.presentation_id
        where ps1.old_sku_id = sl.sku
        -- Prefer non discontinued non deleted products by sorting those to the bottom
        -- This works, because false < true
        order by ps2._deleted nulls first,
                 ps2.visibility = 'Discontinued'
        limit 1
      ) cm on true
      left join real.sellprice_daily sp on sp.sku = cm.sku and sp.date = ps.created_at::date
      where sl.order_invoicing_type = 'postpaid' and sl.amount > 0
        and (ps.service_start_date is null or sl.created_at > ps.service_start_date)
      group by 1, 2
    ),
    invoice_amount as (
      select
        ps.destination_id,
        ps.shipment_id,
        sum(sp.sellprice * il.quantity) as amount
      from pending_shipments ps
      inner join avocado.data_invoice_line il on ps.shipment_id = il.shipment_id
      left join avocado.data_invoice i on i.id = il.invoice_id
      left join avocado.data_invoice_quickbooks iq on iq.invoice_id = i.id
      left join real.sellprice_daily sp on sp.sku = il.sku and sp.date = ps.created_at::date
      where il.quantity > 0
      group by 1, 2
    )
    select
      coalesce(s.destination_id, i.destination_id) as "locationId",
      coalesce(s.shipment_id, i.shipment_id) as "shipmentId",
      coalesce(s.amount, 0)::double precision as "deliveryAmount",
      coalesce(i.amount, 0)::double precision as "deliveryInvoiceAmount"
    from shipment_amount s
    left join invoice_amount i on s.destination_id = i.destination_id and s.shipment_id = i.shipment_id
  `
}

module.exports = {
  getPendingDeliveriesQuery
}
