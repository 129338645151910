module.exports = docToStockCountRecord
module.exports.stockWithAmounts = stockWithAmounts
module.exports.stockWithAvailable = stockWithAvailable
const ONLY_MISSING_PRODUCTS = module.exports.ONLY_MISSING_PRODUCTS = 'products'

const cloneDeep = require('lodash/cloneDeep')
const stockCountIdToLocationProperties = require('./stock-count-id-to-location-properties')
const getSubmitProperties = require('./get-submit-properties')
const shouldTrackBatches = require('./should-track-batches')
const reportProgress = require('./report-progress')
const fromWarehouseUnits = require('../report/tools/from-warehouse-units')
const applyCalculatedFields = require('./apply-calculated-field')
const get = require('lodash/get')
const { getCommitsTotal } = require('./utils/balances')
const { REPORT_BALANCE_FIELD } = require('./utils/constants')
const normaliseStockBatches = require('./normalise-stock-batches')
const { docToReportingPeriodProps } = require('../report/tools/ids')

const addUpBatchQuantities = (batches, countAll = true) =>
  Object.keys(batches).reduce((total, batchId) => {
    const amount = get(batches[batchId], REPORT_BALANCE_FIELD)
    if (countAll && typeof amount === 'number') {
      return total + amount
    }
    return total
  }, 0)

/*
 * set all fields amount that isNaN to 0
 * exclude all remark fields
*/
const sanitizeBatchFieldsAmounts = batches => {
  const putZero = fields => {
    if (!fields) {
      return
    }
    // look for any null or empty amount and put 0 there
    return Object.keys(fields).reduce((acc, fieldId) => {
      acc[fieldId] = Object.assign({}, fields[fieldId])
      if (isNaN(acc[fieldId].amount) || acc[fieldId].amount === null) {
        acc[fieldId].amount = 0
      }
      return acc
    }, {})
  }

  return Object.keys(batches).reduce((batchAccumulator, batchId) => {
    batches[batchId].fields = putZero(batches[batchId].fields)
    batchAccumulator[batchId] = batches[batchId]
    return batchAccumulator
  }, {})
}

function stockWithAmounts (stock) {
  return Object.keys(stock).reduce((withAmounts, productId) => {
    if (!stock[productId].batches) { // unbatched product
      withAmounts[productId] = stock[productId]

      // VAN requires an extra amount field for unbatched products
      let amount = get(stock[productId], REPORT_BALANCE_FIELD)
      if (typeof amount === 'undefined') {
        // Check for a special case where the stock count has no
        // `fields.field:standard-physical-count.amount`, but an `amount` property.
        // This happens when we translate version 1 stock counts which cannot have fields,
        // but will have an `amount` after conversion.
        amount = stock[productId].amount
      }

      if (typeof amount !== 'undefined') {
        withAmounts[productId].amount = amount
      }

      return withAmounts
    }
    const batches = stock[productId].batches
      ? sanitizeBatchFieldsAmounts(stock[productId].batches)
      : undefined
    withAmounts[productId] = {
      amount: addUpBatchQuantities(batches),
      batches
    }
    if (stock[productId].commits) {
      withAmounts[productId].commits = stock[productId].commits
    }
    return withAmounts
  }, {})
}

/*
 * Mutates the passed in object
 * create a deprecation warning on 'available' prop to use availableTotal
 * makes sure the 2 props are always in sync
 */

// Make this global scope, to not spew warnings everywhere
let warnFirstTime = () => {
  console.warn('Stock Count Record: "available" is deprecated, use availableTotal to match ledger balance')
  // Make it noop now, so we only warn once
  warnFirstTime = () => {}
}

const setAvailable = (object, amount) => {
  let available = amount
  Object.defineProperty(object, 'available', {
    configurable: false,
    enumerable: true,
    get () {
      warnFirstTime()
      return available
    },
    set (newVal) {
      warnFirstTime()
      available = newVal
    }
  })

  Object.defineProperty(object, 'availableTotal', {
    configurable: false,
    enumerable: true,
    get () {
      return available
    },
    set (newVal) {
      available = newVal
    }
  })
}

function stockWithAvailable (sourceStock) {
  return Object.keys(sourceStock).reduce((stock, productId) => {
    stock[productId] = cloneDeep(sourceStock[productId])
    const product = stock[productId]
    if (typeof product.amount === 'undefined') {
      return stock
    }
    const commits = product.commits
    if (!commits || Object.keys(commits).length === 0) {
      setAvailable(product, product.amount)
      return stock
    }
    const total = getCommitsTotal(commits)
    setAvailable(product, product.amount - total)
    return stock
  }, {})
}
/*
 * This function has changed to just make sure all products
 * that are passed in are available on the stock object
 */
const addMissingProductsToStock = (doc, service, addMissingStockLevel, products) => {
  const stockWithMissingProducts = (stock = {}, service, locationId, products) => {
    return products.reduce((withProducts, product) => {
      const setProductStockAndReturn = productStock => {
        withProducts[product._id] = Object.assign({}, productStock)
        return withProducts
      }

      const productStock = stock[product._id]

      const areBatchesTracked = shouldTrackBatches({
        service,
        location: { id: locationId }
      })

      const {submitsMultiFieldCounts} = getSubmitProperties(service.id, locationId)

      // if the current report already have stock for that product don't do anything
      let isStockAvailable = typeof get(productStock, 'fields.field:standard-physical-count.amount') !== 'undefined'
      if (areBatchesTracked) {
        isStockAvailable = productStock && productStock.batches && Object.keys(productStock.batches).length
      }
      if (submitsMultiFieldCounts) {
        isStockAvailable = productStock && productStock.fields && Object.keys(productStock.fields).length
      }

      if (isStockAvailable) {
        return setProductStockAndReturn(productStock)
      }

      // ...current report has no stock for that product
      const defaultStock = areBatchesTracked ? { batches: {} } : {}

      // If level ONLY_MISSING_PRODUCTS: just add a default for the missing product
      return setProductStockAndReturn(Object.assign({}, defaultStock, productStock))
    }, {})
  }

  if (addMissingStockLevel !== ONLY_MISSING_PRODUCTS) {
    return doc
  }

  const locationProps = stockCountIdToLocationProperties(doc._id)
  const locationId = locationProps.id
  doc.stock = stockWithMissingProducts(doc.stock, service, locationId, products)
  return doc
}

const submitsWarehouseUnits = service => {
  return service.submitsWarehouseUnits
}

function docToStockCountRecord (doc, service, opts = {}) {
  const products = opts.products
  if (opts.addProgress && products) {
    doc.progress = {
      status: reportProgress(doc, products)
    }
  }

  if (opts.addMissingStock && opts.addMissingStock !== ONLY_MISSING_PRODUCTS) {
    throw new Error('Param addMissingStock: "' + opts.addMissingStock + '" is deprecated, use "products" or getLedgerBalance instead')
  }

  if (opts.convertWarehouseUnits && submitsWarehouseUnits(service) && !products) {
    throw new Error('docToStockCountRecord: can\'t convert warehouseUnits without products')
  }

  if (opts.addMissingStock && products) {
    addMissingProductsToStock(doc, service, opts.addMissingStock, products)
  }

  let {
    _id,
    _rev,
    type,
    version,
    idVersion,
    serviceId,
    stock,
    createdAt,
    updatedAt,
    updatedBy,
    createdBy,
    submittedAt,
    progress,
    expiry,
    partialCount,
    manualFix,
    signed
  } = doc

  const stockCount = Object.assign({}, {
    _id,
    type,
    location: stockCountIdToLocationProperties(_id),
    date: docToReportingPeriodProps(service, doc),
    partialCount: !!partialCount
  })

  if (_rev) {
    stockCount._rev = _rev
  }

  if (service.submitsExpiry) {
    // Re-use batched stock count logic to sum up goods about to expire
    stockCount.expiry = stockWithAmounts(expiry || {})
  }

  if (idVersion) {
    stockCount.idVersion = idVersion
  }
  if (version) {
    stockCount.version = version
  }
  if (createdAt) {
    stockCount.createdAt = createdAt
    stockCount.createdBy = createdBy
    stockCount.updatedAt = updatedAt
    stockCount.updatedBy = updatedBy
  }
  if (submittedAt) {
    stockCount.submittedAt = submittedAt
  }
  if (manualFix) {
    stockCount.manualFix = manualFix
  }
  if (signed) {
    stockCount.signed = signed
  }

  if (stock) {
    if (opts.convertWarehouseUnits && submitsWarehouseUnits(service)) {
      stock = fromWarehouseUnits(stock, products)
    }

    stockCount.stock = stockWithAvailable(stockWithAmounts(stock))
  }
  if (typeof progress !== 'undefined') {
    stockCount.progress = progress
  }
  if (opts.addSubmitConfig) {
    stockCount.submitConfig = getSubmitProperties(service.id, stockCount.location.id)
  }
  stockCount.serviceId = serviceId || service.id
  if (opts.fields) { // apply calculated fields
    const withCalculatedField = applyCalculatedFields(stockCount.stock, opts.fields, opts.fieldOpts)
    if (withCalculatedField) {
      stockCount.stock = withCalculatedField
    }
  }
  if (stockCount.stock) { stockCount.stock = normaliseStockBatches(stockCount.stock) }

  return stockCount
}
