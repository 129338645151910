const {RRule} = require('rrule')
const addDays = require('date-fns/add_days')

const getRuleWeekDay = (day) => {
  switch (day) {
    case '0':
      return RRule.SU

    case '1':
      return RRule.MO

    case '2':
      return RRule.TU

    case '3':
      return RRule.WE

    case '4':
      return RRule.TH

    case '5':
      return RRule.FR

    case '6':
      return RRule.SA

    default:
      return RRule.SU
  }
}

const getUntilDate = (deliveryFrequencyWeeks, refDate) => {
  // The goal is to show at least 2 delivery dates for each route
  // so we add an extra week to make sure of that
  const WEEKLYUPDATE = 15
  const days = WEEKLYUPDATE + ((deliveryFrequencyWeeks - 1) * 7)
  return addDays(refDate, days)
}

const INTEGER_TO_DISPLAY_DAY = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday'
}

const DISPLAY_DAY_TO_INTEGER = {
  'Sunday': 0,
  'Monday': 1,
  'Tuesday': 2,
  'Wednesday': 3,
  'Thursday': 4,
  'Friday': 5,
  'Saturday': 6
}

// SL route docs have 'day' prop that's a number 0 - 6
// The ops sheet expects a visit_day shown as day of week.
// (the lib rrule does not return a display day)
function getVisitDisplayDay (inputDay) {
  const displayDay = INTEGER_TO_DISPLAY_DAY[inputDay]

  // this is validated on route doc save but just in case
  return (inputDay === undefined || inputDay === null || !displayDay)
    ? null
    : displayDay
}

const RouteStatus = {
  PROVISIONAL: 'provisional',
  IN_REVIEW: 'in_review',
  APPROVED: 'approved',
  IN_TRANSIT: 'in_transit',
  COMPLETED: 'completed'
}

module.exports = {
  getUntilDate,
  getRuleWeekDay,
  getVisitDisplayDay,
  INTEGER_TO_DISPLAY_DAY,
  DISPLAY_DAY_TO_INTEGER,
  RouteStatus
}
