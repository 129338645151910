const { DBNames } = require('../common/db-names')
const { CouchDbPgDatabase } = require('./couchdb-pg-database')
const { CouchDbRestDatabase } = require('./couchdb-rest-database')

class CouchDbCache {
  constructor (pgPool, agave) {
    this.pg = {
      [DBNames.StockCounts]: new CouchDbPgDatabase(pgPool, DBNames.StockCounts)
    }
    this.rest = {
      [DBNames.StockCounts]: new CouchDbRestDatabase(agave, DBNames.StockCounts)
    }
  }
}

module.exports = CouchDbCache
