/* global localStorage */
import {
  getSyncDBs,
  LIVE_DOWNLOAD_OPTIONS,
  LIVE_UPLOAD_OPTIONS
} from './databases'

import {
  setActive,
  updatePending,
  setComplete
} from '../root/reducers/common/sync'

import { firstReplicateAllDocs, checkForCheckpoint } from './first-replicate-alldocs'
import { firstReplicateIds, checkForIDCheckpoint } from './first-replicate-ids'
import { liveDownloadById } from './live-download-by-id'
import { simpleThrottle } from './utils'
import { monitorReplicationEvents } from './common'

let dbConfigs

export async function initSync (user, remoteDbUrl, dbList, dispatch) {
  dbConfigs = getSyncDBs(remoteDbUrl, dbList, user)
  // 1. Get list of DBs which have not been initialized
  const needFirstDownloadDbs = await getDbsNeedingFirstDownload(dbConfigs, user)
  const waitForDownload = needFirstDownloadDbs.length > 0

  // NOTE: that we're mutating the DB configs through these steps
  // Also NOTE: No await below here, we need things to happen on top of each other
  // So we can either wait for initialization to complete or return early
  // if no initialization is needed

  // 2. Get id dispensers
  const initialization = getIdCheckpoints(user, dbConfigs, dispatch)
    .then(() => {
      const promises = needFirstDownloadDbs.map(dbName => {
        const dbConfig = dbConfigs.find(dbConfig => dbName === dbConfig.name)

        if (!dbConfig) {
          throw new Error(`DB ${dbName} has no valid configuration`)
        }

        dispatch(setActive(dbConfig.name, 'download'))
        const promise = dbConfig.syncOnId
          ? firstReplicateIds(user, dbConfig, dispatch)
          : firstReplicateAllDocs(user, dbConfig, dispatch)
        promise.then(() => dispatch(setComplete(dbConfig.name, 'download')))
        promise.then(() => writeInitializedDoc(dbConfig, user))
        return promise
      })

      return Promise.all(promises)
    })
    .catch(e => {
      if (!waitForDownload) {
        return
      }

      // Re-throw and don't let the user initialize
      throw e
    })

  // 3. After initialization, start live replications
  initialization.then(() => {
    dbConfigs.forEach(dbConfig => {
      if (dbConfig.syncOnId) {
        liveDownloadById(dbConfig, user, dispatch)
      } else {
        liveDownloadByAllDocs(dbConfig, dispatch)
      }
      if (dbConfig.bidirectional) {
        liveUpload(dbConfig, dispatch)
      }
    })
  })

  // 4. If we need to initialize, wait for that
  if (waitForDownload) {
    return initialization
  }

  // 5. All dbs have been initialized before
  //    you can start using the app while we sync
  //    in the background
  return Promise.resolve()
}

function liveDownloadByAllDocs (dbConfig, dispatch) {
  const { localDb, remoteDb, name } = dbConfig
  const downloadFeed = localDb.replicate.from(remoteDb, LIVE_DOWNLOAD_OPTIONS)
  downloadFeed.on('change', ({ pending }) => dispatch(updatePending(name, 'download', pending || 0)))
  monitorReplicationEvents(downloadFeed, name, 'download', dispatch)
  return downloadFeed
}

// There are two change listeners here which both dispatch pending upload counts.
// One only listens to changes on the replication upload feed; the other listens to all localDB
// changes and throttles a call to dispatch
function liveUpload (dbConfig, dispatch) {
  const { localDb, remoteDb, name, uploadOptions } = dbConfig
  const feed = localDb.replicate.to(remoteDb, uploadOptions || LIVE_UPLOAD_OPTIONS)
    .on('change', () => {
      localDb.getPendingUploadCount().then(({ pending }) => {
        dispatch(updatePending(name, 'upload', pending))
      })
    })
  monitorReplicationEvents(feed, name, 'upload', dispatch)
  // initPendingUpload and getPendingUploadCount are pouchdb-pending-upload plugin calls
  // for proxy counts of pending progress.
  localDb.initPendingUpload(feed)
  localDb.changes({ live: true, since: 'now' })
    .on('change', simpleThrottle(() => {
      localDb.getPendingUploadCount().then((info) => {
        dispatch(updatePending(name, 'upload', info.pending))
      })
    }, 1000))
}

/*
 * Calls ID dispensers and mutates the dbConfig object
 * to have id dispensing info
 */
const getIdCheckpoints = (user, dbConfigs, dispatch) => {
  return Promise.all(dbConfigs.map(dbConfig => {
    if (dbConfig.syncOnId) {
      return checkForIDCheckpoint(user, dbConfig, dispatch)
    }

    return checkForCheckpoint(dbConfig)
  }))
}

const INITIALIZED_DOC_ID = '_local/db_initialization'

const getDbsNeedingFirstDownload = async (dbConfigs, user) => {
  const syncId = `${INITIALIZED_DOC_ID}:${user._id}`
  const needsInitialization = []
  for (let dbConfig of dbConfigs) {
    try {
      await dbConfig.localDb.get(syncId)
    } catch (e) {
      needsInitialization.push(dbConfig.name)
    }
  }

  return needsInitialization
}

const writeInitializedDoc = (dbConfig, user) => {
  const syncId = `${INITIALIZED_DOC_ID}:${user._id}`
  return dbConfig.localDb.get(syncId)
    .catch(() => { return {} })
    .then(doc => {
      return dbConfig.localDb.put({
        _id: syncId,
        _rev: doc._rev,
        time: new Date().toJSON()
      })
    })
}

export function clearLocalDatabases () {
  const localDbs = dbConfigs
    // dont filter bidirectional here, we wanna clear all
    .map(dbConfig => dbConfig.localDb)
    .filter(x => x)

  localStorage.removeItem('session')
  localStorage.removeItem('notifications')
  localStorage.removeItem('retail.next_visit')
  localStorage.removeItem('retail.invoices')
  localStorage.removeItem('initialSyncStatus')
  localStorage.removeItem('usingFlags')

  return Promise.all(localDbs.map(db => db.destroy()))
}

// TODO: use default param as above dbConfigs and expose this to tests
export async function getTotalPendingUploadCount () {
  const localDbs = dbConfigs
    // only bidirectional counts has an upload
    .filter(dbConfig => dbConfig.bidirectional)
    .map(dbConfig => dbConfig.localDb)
    .filter(x => x)

  let pendingUploadCount = 0
  for (let i = 0; i < localDbs.length; i++) {
    const {pending} = await localDbs[i].getPendingUploadCount()
    pendingUploadCount += pending
  }

  return pendingUploadCount
}
