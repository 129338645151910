import React from 'react'
import PropTypes from 'prop-types'

import { Page } from '@fielded/shared-ui'
import { Home as IconHome } from '@fielded/shared-ui/src/icons'
import ScrollToTop from '@fielded/shared-ui/src/common/ScrollToTop'

import withConfig from '../van-shared/hoc/withConfig'
import { hasFeature } from '../van-shared/utils/features'
import HomeShelfLife from './HomeShelfLife'
import HomePsm from './HomePsm'

const Home = ({
  appName,
  app,
  location,
  user,
  error,
  psmUserRequireFillEmail,
  config,
  api,
  history
}) => {
  const hasWhiteLabel = hasFeature(config, 'features.useWhiteLabel')

  return (
    <div>
      <div className='home__content'>
        <ScrollToTop />
        <Page title={config.title}>
          <Page.Header
            title={`Welcome to ${hasWhiteLabel ? 'Field Supply' : appName}`}
            breadcrumbItems={[
              {
                title: 'Home',
                icon: <IconHome />
              }
            ]}
          />
          <Page.Panel>
            <Page.Panel.Section>
              {app === 'shelflife' && (
                <HomeShelfLife
                  user={user}
                  error={error}
                />
              )}
              {app !== 'shelflife' && (
                <HomePsm
                  config={config}
                  api={api}
                  location={location}
                  showProfileLink={psmUserRequireFillEmail}
                  user={user}
                  history={history}
                />
              )}
            </Page.Panel.Section>
          </Page.Panel>
        </Page>
      </div>
    </div>
  )
}

Home.propTypes = {
  appName: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.object,
  error: PropTypes.string,
  psmUserRequireFillEmail: PropTypes.bool,
  api: PropTypes.object,
  config: PropTypes.object
}

Home.defaultProps = {
  user: {}
}

export default withConfig(Home)
