const SHELFLIFE_ROLES = [
  {
    'roleId': 'feature:userRole:planner',
    'displayName': 'Planner',
    'userRequiresRoutes': false,
    'defaultFeatures': [
      'feature:stock-reporting',
      'feature:settings',
      'feature:settings:planning-calculator',
      'feature:shipments',
      'feature:online-offline',
      'feature:orders:requester',
      'feature:orders:bulk',
      'feature:orders:reset'
    ]
  },
  {
    'roleId': 'feature:userRole:operator',
    'displayName': 'Operator',
    'userRequiresRoutes': false,
    'defaultFeatures': [
      'feature:shipments',
      'feature:stock-reporting',
      'feature:settings:facilities:approvals',
      'feature:settings:products:readonly',
      'feature:online-offline',
      'feature:stock-reporting-add-product'
    ]
  },
  {
    'roleId': 'feature:userRole:fp',
    'displayName': 'FP',
    'userRequiresRoutes': true,
    'defaultFeatures': [
      'feature:stock-reporting',
      'feature:shipments'
    ]
  },
  {
    'roleId': 'feature:userRole:sales',
    'displayName': 'Sales',
    'userRequiresRoutes': false,
    'defaultFeatures': [
      'feature:stock-reporting',
      'feature:settings:facilities:approvals',
      'feature:settings:facilities:offline-edits'
    ]
  },
  {
    'roleId': 'feature:userRole:external-planner',
    'displayName': 'External planner',
    'userRequiresRoutes': false,
    'defaultFeatures': [
      'feature:settings:facilities',
      'feature:read-only-reports',
      'feature:shipments',
      'feature:online-offline',
      'feature:orders:requester',
      'feature:orders:bulk',
      'feature:orders:reset'
    ]
  }
]
const PSM_ROLES = [
  {
    'roleId': 'feature:userRole:psm-national',
    'allowedUsers': ['country'],
    'levels': ['country'],
    'displayName': 'National',
    'nonRouteUser': true,
    'defaultFeatures': [
      'feature:online-offline',
      'feature:forecasting',
      'feature:global-shipments',
      'feature:qc-alerts',
      'feature:read-only-reports',
      'feature:planning'
    ]
  },
  {
    'roleId': 'feature:userRole:psm-state',
    'allowedUsers': ['country', 'state'],
    'levels': ['state'],
    'displayName': 'State',
    'nonRouteUser': true,
    'defaultFeatures': [
      'feature:online-offline',
      'feature:stock-reporting',
      'feature:shipments',
      'feature:settings:facilities:changes',
      'feature:settings:products:readonly',
      'feature:settings:routePlanning',
      'feature:qc-alerts',
      'feature:orders:requester'
    ]
  },
  {
    'roleId': 'feature:userRole:psm-distribution-manager',
    'allowedUsers': ['country', 'state'],
    'levels': ['country', 'state'],
    'displayName': 'Distribution Manager',
    'nonRouteUser': true,
    'defaultFeatures': [
      'feature:settings:routeReviewing',
      'feature:routes',
      'feature:settings:users',
      'feature:shipments',
      'feature:online-offline'
    ]
  },
  {
    'roleId': 'feature:userRole:psm-lga',
    'levels': ['lga'],
    'allowedUsers': ['country', 'state', 'lga'],
    'displayName': 'Lga',
    'nonRouteUser': true,
    'defaultFeatures': [
      'feature:read-only-reports',
      'feature:online-offline'
    ]
  },
  {
    'roleId': 'feature:userRole:psm-facility',
    'allowedUsers': ['country', 'state', 'lga', 'sdp'],
    'levels': ['sdp'],
    'displayName': 'Facility',
    'nonRouteUser': true,
    'defaultFeatures': [
      'feature:read-only-reports',
      'feature:qc-alerts',
      'feature:stock-reporting',
      'feature:shipments',
      'feature:online-offline'
    ]
  },
  {
    'roleId': 'feature:userRole:psm-warehouse',
    'levels': ['name'],
    'allowedUsers': ['country'],
    'displayName': 'Warehouse',
    'nonRouteUser': true,
    'defaultFeatures': [
      'feature:orders:warehouse',
      'feature:orders:reset'
    ]
  },
  {
    'roleId': 'feature:userRole:psm-vendor',
    'levels': ['vendor'],
    'allowedUsers': ['country'],
    'displayName': 'Vendor',
    'nonRouteUser': false,
    'defaultFeatures': [
      'feature:routes',
      'feature:settings:users',
      'feature:online-offline',
      'feature:lastMileDeliveries'
    ]
  },
  {
    'roleId': 'feature:userRole:psm-driver',
    'levels': ['state'],
    'allowedUsers': ['vendor'],
    'displayName': 'Driver',
    'nonRouteUser': false,
    'defaultFeatures': [
      'feature:shipments',
      'feature:online-offline'
    ]
  }
]

const listUserRoles = async (state, user, tenant = 'shelflife') => {
  const rolesByTenant = {
    shelflife: SHELFLIFE_ROLES,
    psm: PSM_ROLES
  }
  if (!rolesByTenant[tenant]) {
    throw new Error(`listUserRoles: roles for tenant ${tenant} not found`)
  }

  if (tenant === 'shelflife') return rolesByTenant[tenant]

  // For PSM we want to only show what the user is allowed to view
  const userRole = user.roles.find(role => role.includes('userRole'))

  if (!userRole) {
    throw new Error('listUserRoles: userRole not found in user doc')
  }

  const userLevels = PSM_ROLES.find(roles => roles.roleId === userRole).levels

  const rolesToDisplay = PSM_ROLES.filter(roles =>
    userLevels.some(level => roles.allowedUsers.includes(level))
  )

  return rolesToDisplay
}
module.exports = {
  listUserRoles,
  PSM_ROLES,
  SHELFLIFE_ROLES }
