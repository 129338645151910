const snakeCase = require('lodash/snakeCase')
const EntityApi = require('./entity-api')
const { singularize } = require('../utils/pluralize')

const supportedActionNames = [
  'list',
  'get',
  'create',
  'update',
  'delete'
]

/**
 * Proxified Entity API for dynamically generating method proxies, so an API call will trigger an HTTP request to agave
 * instead of e.g. trying to directly connect to PostgreSQL if there's no PostgreSQL connection.
 * Extends the EntityApi and allows for the dynamic creation of proxy API methods based on provided method names.
 * @class
 * @extends EntityApi
 */
class ProxifiedEntityApi extends EntityApi {
  constructor (entityName, methodsNeedingProxy, needsProxy, adapter, agaveAdapter) {
    super(adapter)
    this.agaveAdapter = agaveAdapter
    if (needsProxy) {
      this.proxifyMethods(entityName, methodsNeedingProxy)
    } else {
      // Return the routes so our HTTP REST API can automatically link routes to api methods
      this.proxyAutoRoutes = this.getURLPaths(entityName, methodsNeedingProxy)
    }
  }

  getProxyConfig (entityName, methodName, restAction) {
    // Set default values for sub-entity/method name and REST adapter action
    let subEntityOrMethodName = methodName // Default to the method name
    let restAdapterAction = 'GET' // Default to basic GET request

    const snakeMethodName = snakeCase(methodName)

    // Split snake case method name into action and sub-entity parts
    const [actionNameCandidate, ...subEntityCandidateParts] = snakeMethodName.split('_')

    // If the action is supported, update restAdapterAction and subEntityOrMethodName
    if (supportedActionNames.includes(actionNameCandidate)) {
      restAdapterAction = actionNameCandidate // Update restAdapterAction
      // Join sub-entity parts
      subEntityOrMethodName = subEntityCandidateParts.join('-')
      if (actionNameCandidate === 'list') {
        // convert to singular form so to accoun for list method plurals (e.g. listLocations should be a GET on /location/)
        subEntityOrMethodName = singularize(subEntityOrMethodName)
      }
    } else {
      // If the action is not supported, the method name is the sub-entity name
      subEntityOrMethodName = snakeCase(methodName).split('_').join('-')
    }

    // "http://dev.psm.localhost:3000/agave/v1/shipment/last-mile-delivery/?limit=500"
    const pathToEndpoint = `${entityName}/${subEntityOrMethodName}`

    return {
      restAdapterAction: restAction || restAdapterAction,
      pathToEndpoint
    }
  }

  proxify (func, entityName, methodName, restAction) {
    this[methodName] = async (params) => {
      // Build path to endpoint
      const {restAdapterAction, pathToEndpoint} = this.getProxyConfig(entityName, methodName, restAction)
      return this.agaveAdapter[restAdapterAction](pathToEndpoint, params)
    }
  }

  proxifyMethods (entityName, methodsNeedingProxy) {
    methodsNeedingProxy.forEach(method => {
      const {methodName, restAction} = typeof method === 'object'
        ? method
        : {methodName: method}
      this.proxify(this[methodName], entityName, methodName, restAction)
    })
  }

  getURLPaths (entityName, methodsNeedingProxy) {
    const paths = methodsNeedingProxy.map(methodName => {
      const config = this.getProxyConfig(entityName, methodName)
      return config && config.pathToEndpoint
    })
    return paths
  }
}

module.exports = ProxifiedEntityApi
